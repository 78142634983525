import {makeStyles} from '@material-ui/core/styles';
import React, {useState, useEffect} from 'react';
import Collections from "./Utils/collections";
import {
    useHistory,
    //BrowserRouter as Router,
    //Switch,
    Route
} from 'react-router-dom'
import NavBar from './NavBar/NavBar'
//import AuthService from './AuthService'
import AuthService from "./Services/AuthService";
import Login from './Login/Login'
//import OutOfService from './Ajustes/OutOfService.js'
import MisMetas from './Objetivos/Objetivos'
import MiPerfil from './Perfil/Perfil'
//import Register from './Login/Register'
import ResetPass from './Login/ResetPass'
import CreatePassword from './Login/CreatePassword'
import Recruitment from './Reclutamiento/Reclutamiento'
import Answering from './Asistire/Asistire'
import Inicio from './Inicio/Inicio'
import Directorio from './Directorio/Directorio'
import Recursos from './Recursos/Recursos'
import Ajustes from './Ajustes/Ajustes'
import GestionAdmins from './Ajustes/GestionAdmins'
import Calendario from './Calendario/Calendario'
import Eventos from './NavBar/ListadoEventos'
// socket.io
import io from 'socket.io-client';
import {ToastContainer} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {css} from "glamor";
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';



//To Do Ver2: OutOfService Temporal Function:
//To Do Ver3: Create a static function for Maintenance Windows: Using this text to make a Commit Test.

//AuthService.logout();


//const socketClient = io(Collections.ipServer);
/*
socketClient.on("requestMeetingNtfc_" + idUserLoged, col => {
  toast(col.ntfc, {
    className: css({
      background: "black"
    }),
    progressClassName: css({
      background: "purple"
    }),
    onClose: function() {}
  });
});
*/

const drawerWidth = 240;
const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexGrow: 1,
    },
    toolbar: {
        paddingRight: 24, // keep right padding when drawer closed
        backgroundColor: '#fff',
    },
    toolbarIcon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
    LogoHolder: {
        marginTop: '10px',
        marginBottom: '10px'
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: 36,
    },
    menuButtonHidden: {
        display: 'none',
    },
    title: {
        color: 'black',
        flexGrow: 1,
        display: 'none',
        [theme.breakpoints.up('sm')]: {
            display: 'block',
        },
    },
    drawerPaper: {
        position: 'relative',
        whiteSpace: 'nowrap',
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
        height:"100%"
    },
    sideMenus:{
        height:"100vh"
    },
    drawerPaperClose: {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        width: 0, //theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9),
        },
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
    },
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    fixedHeight: {
        height: 850,
    },
    search: {
        color: 'black',
        flexGrow: 4,
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        // backgroundColor: alpha(theme.palette.common.white, 0.15),
        backgroundColor: 'rgba(255,255,255,0.15)',
        '&:hover': {
            // backgroundColor: alpha(theme.palette.common.white, 0.25),
            backgroundColor: 'rgba(255,255,255,0.15)',
        },
        marginLeft: 0,
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing(1),
            width: 'auto',
        },
    },
    searchIcon: {
        padding: theme.spacing(0, 2),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    inputRoot: {
        color: 'black',
        marginLeft: '50px'
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            width: '12ch',
            '&:focus': {
                width: '20ch',
            },
        },
    },
}));

const App = () => {
    let history = useHistory();
    const classes = useStyles();
    const [currentUser, setCurrentUser] = useState(AuthService.getCurrentUser());
    const [hstrl, setHstrl] = useState(window.localStorage.getItem("titleToolbar") || '');
    const [dataRecruitment, setDataRecruitment] = useState("");
    const [dataAnswering, setDataAnswering] = useState("");
    const [dataCreatePassword, setDataCreatePassword] = useState("");
    const socket = io(Collections.ipServer);



    useEffect(() => {
        // 1. Set up the browser history with the updated location
        // (minus the # sign)
        const path = (/#!(\/.*)$/.exec(window.location.hash) || [])[1];
        //console.log(path);
        if (path) {
            history.replace(path);
        }
        const user = AuthService.getCurrentUser();
        //To Do VerZ4: Improve this sesion validator for new updates.
        /*
        function prettyJSONv(obj) {
            let stringed = JSON.stringify(obj, null, 2);
            return stringed
        }
        let stringeduser = prettyJSONv(user);
        console.log("Información del Usuario Activo: " + stringeduser);
        console.log('Usuario Activo Detail Correo: '+user.correo);
        */
       
        var VersionCheckPoint = localStorage.getItem("VersionCheckPoint");
        console.log('------> El valor de VersionCheckPoint es: '+VersionCheckPoint);
        if(!VersionCheckPoint){
            localStorage.setItem("VersionCheckPoint", Collections.CurrentAppVersion);
            AuthService.logout();
            window.location.reload();
        } else if (VersionCheckPoint !== Collections.CurrentAppVersion){
            localStorage.setItem("VersionCheckPoint", Collections.CurrentAppVersion);
            AuthService.logout();
            window.location.reload();
        }
        console.log('Environment IPserver:' +Collections.ipServer);
        //End Of To Do-----
        console.log(btoa(user));
        if (user) {
            AuthService.UserStatusCheck(user.correo)
                .then(resp =>{
                    if(resp === true){
                        AuthService.logout();
                        window.location.reload();
                    }
                });
            setCurrentUser(user);
            AuthService.consult(`{ventasApi{ id idRqst ssid status message results createdAt updatedAt }}`)
                .then(resp =>{
                    let ventasApi = resp.data.data.ventasApi;
                    if(ventasApi.length>0){
                        window.localStorage.setItem('idApiVentas', JSON.stringify(resp.data.data.ventasApi[0]));
                    }
                });
        }
        if(window.location.pathname === '/recruitment'){
            //console.log("Vamos a guardar el path de reclutamiento: " + window.location.search);
            if(window.location.search && window.location.search.length>0){
                setDataRecruitment(window.location.search);
                window.localStorage.setItem('titleToolbar', 'recruitment');
                history.push('/recruitment');
                setHstrl('/recruitment');
            } else {
                window.localStorage.removeItem('titleToolbar');
                setHstrl('');
            }
        }
        if(window.location.pathname === '/answering'){
            //console.log("Vamos a guardar el path de reclutamiento: " + window.location.search);
            if(window.location.search && window.location.search.length>0){
                setDataAnswering(window.location.search);
                window.localStorage.setItem('titleToolbar', 'answering');
                history.push('/answering');
                setHstrl('/answering');
            } else {
                window.localStorage.removeItem('titleToolbar');
                setHstrl('');
            }
        }
        
        if(window.location.pathname === '/createPassword'){
            //console.log("Vamos a guardar el path de reclutamiento: " + window.location.search);
            if(window.location.search && window.location.search.length>0){
                setDataCreatePassword(window.location.search);
                window.localStorage.setItem('titleToolbar', 'createPassword');
                history.push('/createPassword');
                setHstrl('/createPassword');
            } else {
                window.localStorage.removeItem('titleToolbar');
                setHstrl('');
            }
        }

        let hstr = window.localStorage.getItem("titleToolbar");
        history.push("/" + (hstr ? hstr.replace("?add=true", "").replace(" ", "") : '').toLowerCase());
        if (!user) {
            if(window.location.pathname !== '/resetpass') {
                AuthService.logout();
            }
        }
        //console.log(typeof(hstrl) + " --> " + hstrl);
    }, []);

    return (
            <div>
                <ToastContainer
                    autoClose={5000}
                    className="toast-container"
                    toastClassName="dark-toast"
                    progressClassName={css({
                        height: "2px"
                    })}
                />
                {currentUser ? (

                        <div className={classes.root}>
                            <CssBaseline/>

                            <NavBar classes={classes} datauser={currentUser} socket={socket}/>

                            <main style={{
                                flexGrow: 1,
                                height: "100vh",
                                overflow: "auto"
                            }}>
                                <Container maxWidth="lg" className='main_div'>

                                    <div className="col s12 container-setup" style={{marginTop: "68px"}}>
                                        {
                                            currentUser.updatePassword ?
                                                <MiPerfil classes={classes} user={currentUser} editar={true}/>
                                                :
                                            (hstrl==='Calendario' || hstrl==='Calendario?add=true') ?
                                                <Calendario classes={classes} datauser={currentUser} socket={socket}/>
                                                :
                                                (hstrl==='Directorio') ?
                                                    <Directorio classes={classes} datauser={currentUser} />
                                                    :
                                                    (hstrl==='Ajustes') ?
                                                        <Ajustes classes={classes} />
                                                    :
                                                    (hstrl==='Gestionar Administradores') ?
                                                        <GestionAdmins classes={classes} />
                                                        :
                                                        (hstrl==='Recursos') ?
                                                            <Recursos classes={classes} user={currentUser}/>
                                                            :
                                                            (hstrl==='Mi Perfil') ?
                                                                <MiPerfil classes={classes} user={currentUser} editar={true}/>
                                                                :
                                                                (hstrl==='Mis Metas') ?
                                                                    <MisMetas classes={classes} user={currentUser} />
                                                                :
                                                                (hstrl==='Eventos') ?
                                                                    <Eventos classes={classes} user={currentUser}/>
                                                                    :
                                                                    <Inicio classes={classes} user={currentUser}/>
                                        }
                                        {/*
                                        <Router>
                                            <Switch>

                                                <Route exact path="/">
                                                    <Inicio classes={classes}/>
                                                </Route>
                                                <Route exact path="/inicio" >
                                                    <Inicio classes={classes} />
                                                </Route>
                                                <Route exact path="/directorio" >
                                                    <Directorio classes={classes} datauser={currentUser} />
                                                </Route>
                                                <Route exact path="/calendario" >
                                                    <Calendario classes={classes} datauser={currentUser} />
                                                </Route>
                                                <Route exact path="/miCalendar" >
                                                    <MyCalendar classes={classes} />
                                                </Route>
                                                <Route exact path="/recursos" >
                                                    <Recursos classes={classes} />
                                                </Route>
                                                <Route exact path="/ajustes" >
                                                    <Ajustes classes={classes} />
                                                </Route>
                                                <Route exact path="/miperfil" >
                                                    <MiPerfil classes={classes} user={currentUser} editar={true}/>
                                                </Route>

                                            </Switch>
                                        </Router>
                                        */}

                                        {/*
                                        <SecuredRoute path="/dashboard" component={Inicio} />
                                        <SecuredRoute path="/agenda" component={Calendario} />
                                        <SecuredRoute path="/schedule" component={Schedule} />
                                    */}

                                    </div>
                                </Container>
                            </main>
                        </div>
                    ) :
                    (
                        <div className="container mt-3">
                            {
                                (hstrl === '' || hstrl === '/') ?
                                    <Route exact path="*" component={Login}/>
                                    :
                                hstrl.indexOf('recruitment')>=0 && dataRecruitment.length>0 ?
                                    <Recruitment datos={dataRecruitment}/>
                                    :
                                hstrl.indexOf('answering')>=0 && dataAnswering.length>0 ?
                                    <Answering datos={dataAnswering}/>
                                    :
                                hstrl.indexOf('createPassword')>=0 && dataCreatePassword.length>0 ?
                                    <CreatePassword datos={dataCreatePassword}/>
                                    :
                                    <Route path="/resetPass" component={ResetPass}/>
                            }
                            {
                            /*
                            <Switch>
                                <Route path="/login" component={Login}/>
                                <Route path="/resetPass" component={ResetPass}/>
                                <Route path="/register" component={Register}/>
                                <Route path="/recruitment" component={Register}/>
                                <Route path="/answering" component={Register}/>
                                <Route exact path="*" component={Login}/>
                            </Switch>
                            */
                            }
                        </div>

                        /*
                        <div>
                            <Route exact path="/login" component={Login}/>
                            <Route exact path="/register" component={Register}/>
                            <Route exact path="*" component={Login}/>
                        </div>
                        */
                    )
                }

            </div>
    )
};

export default App;