import React, { useState, useEffect } from 'react';
//import { View, Switch, StyleSheet } from "react-native";
import clsx from 'clsx';
//import { useHistory } from 'react-router-dom';

import AuthService from "../Services/AuthService";

import { Calendar, Views, momentLocalizer } from 'react-big-calendar'
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop'
//import 'react-big-calendar/lib/addons/dragAndDrop/styles.scss'
import 'react-big-calendar/lib/css/react-big-calendar.css';

//import moment_timezone from 'moment-timezone';
import moment from 'moment';
import {ToastsContainer, ToastsStore, ToastsContainerPosition} from 'react-toasts';
//import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';

import Button from '@material-ui/core/Button';
//import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
//import DialogTitle from '@material-ui/core/DialogTitle';
import Tooltip from '@material-ui/core/Tooltip';
import CancelIcon from '@material-ui/icons/Cancel';
import { withStyles} from '@material-ui/core/styles';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';
import Divider from '@material-ui/core/Divider';
import StarIcon from '@material-ui/icons/Star';
//import MoreVertIcon from '@material-ui/icons/MoreVert';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
//import { alpha } from '@material-ui/core/styles'
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import StopIcon from '@material-ui/icons/Stop';
import DatePicker, { registerLocale } from "react-datepicker";

import es from "date-fns/locale/es"; // the locale you want
import "react-datepicker/dist/react-datepicker.css";

import Asistencia from './Asistencia';
import NativeSelect from '@material-ui/core/NativeSelect';
import Checkbox from '@material-ui/core/Checkbox';

import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
//import Popper from '@material-ui/lab/Popper';
import CircularProgress from "@material-ui/core/CircularProgress";
//import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
//import CheckBoxIcon from "@material-ui/icons/CheckBox";
import {toast} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {css} from "glamor";
import utils from "../Utils/collections";
import Lottie from 'react-lottie-player'
import loading from "../Lottie/33637-ondas-sonoras.json"
//import { TurnedIn } from '@material-ui/icons';
registerLocale("es", { ...es, options: { ...es.options, weekStartsOn: 0 } }); // register it with the name you want

//const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
//const checkedIcon = <CheckBoxIcon fontSize="small" />;

const GreenCheckbox = withStyles({
    root: {
        color: "#00AC46",
        '&$checked': {
            color: "#00AC46",
        },
    },
    checked: {},
})((props) => <Checkbox color="default" {...props} />);

const DragAndDropCalendar = withDragAndDrop(Calendar);
const localizer = momentLocalizer(moment); // or globalizeLocalizer

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const AntSwitch = withStyles((theme) => ({
    root: {
        width: 58,
        height: 30,
        padding: 0,
        display: 'flex',
        margin: 10,
    },
    switchBase: {
        padding: 2,
        color: "#00AC46",
        '&$checked': {
            transform: 'translateX(20px)',
            color: "#00AC46",
            '& + $track': {
                opacity: 1,
                backgroundColor: theme.palette.common.white,
                borderColor: theme.palette.common.darkGray,
            },
        }
    },
    thumb: {
        width: 5,
        height: 5,
        boxShadow: 'none',
        "&:before": {
            display: "none"
        }
    },
    track: {
        border: `1px solid ${theme.palette.grey[500]}`,
        borderRadius: 16 / 2,
        opacity: 1,
        backgroundColor: theme.palette.common.white,
        borderColor: theme.palette.common.darkGray,
    },
    checked: {},
}))(Switch);

/*
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};
*/

/**
 * Componente del calendario
 * @param {*} props datos del usuario que esta logeado
 * @returns la vista del calendario
 */
export default function Calendario (props) {
    //let history = useHistory();
    const [completed, setCompleted] = useState(false);
    const [classes] = useState(props.classes);
    const [user] = useState(props.datauser);
    console.log('----> valor de user de props: '+user.idu);
    const [socket] = useState(props.socket);
    const [eventsDay, setEventsDay] = useState([]);
    const [eventsMonth, setEventsMonth] = useState([]);
    //const [openMenu, setOpenMenu] = useState(localStorage.getItem("openMenu"));
    const [openModal, setOpenModal] = useState(false);
    const [culture] = useState("es-mx");
    const [defaultDate, setDefaultDate] = useState(new Date());
    //const [defaultMonth, setDefaultMonth] = useState(new Date().getMonth());
    const [eventDate, setEventDate] = useState(new Date());
    const [eventDateHasta, setEventDateHasta] = useState(new Date());
    const [eventosPorRol, setEventosPorRol] = useState([]);
    const [eventosAll, setEventosAll] = useState([]);
    const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);
    const [stateSwitch, setStateSwitch] = React.useState({ checkedModalidad1: true, checkedModalidad2: true });
    const [SaveBtnState, setSaveBtnState] = useState(false);
    let horaEnd = ( new Date().getHours()===23 ? 0: (new Date().getHours()+1) );
    let minutosEnd = new Date().getMinutes();
    const eventoNuevo = {
        id: "",
        titulo: "",
        fecha: "",
        start: (new Date().getHours()<10?'0'+new Date().getHours().toString():new Date().getHours())
        +':'+(new Date().getMinutes()<10?'0'+new Date().getMinutes().toString():new Date().getMinutes()),
        end: (horaEnd<10?'0'+horaEnd.toString():horaEnd) + ':' + (minutosEnd<10?'0'+minutosEnd.toString():minutosEnd),
        color: "",
        idTipo: "0",
        tipo: "",
        modalidad1: stateSwitch.checkedModalidad1 ? 'party': 'individual',
        modalidad2: stateSwitch.checkedModalidad2 ? 'presencial': 'virtual',
        notas: "",
        direccion: "",
        listaInvitados: "",
        iduPresentador: "",
        correoPresentador: user.correo,
        nombrePresentador: user.nombre,
        seRepite: false,
        frecuencia: "0",       // 1 Semanal, 2 Mensual, 3 Anual
        seRepiteHasta: "",
        idEventoLigado: "",
        status: 1,  // 1 - Nuevo evento, 0 - Evento cancelado, 2 - Evento re agendado
        idAppointment: "",
        fechaReagenda: "",
        fechaReagendaFormato: "",
        horaInicioReagenda: ""
    };
    const [datosEvento, setDatosEvento] = React.useState(eventoNuevo);
    const [seRepite, setSeRepite] = React.useState(false);
    const [idTipoEvento, setIdTipoEvento] = React.useState("0");
    const [EventoRepetable, setEventoRepetable] = React.useState(false);
    const [tipoEvento, setTipoEvento] = React.useState("");
    const [modalidad1, setModalidad1] = React.useState(false);
    const [modalidad2, setModalidad2] = React.useState(false);
    const [editaTitulo, setEditaTitulo] = React.useState(false);
    // eslint-disable-next-line no-unused-vars
    const [periodicidad, setPeriodicidad] = React.useState(false);

    const [listaInvitados, setListaInvitados] = React.useState([]);
    const [personas, setPersonas] = React.useState([]);
    const [titlePersonas, setTitlePersonas] = React.useState("Personas");

    const [fechaTitle, setFechaTitle] = React.useState(moment().format('DD/MM/YYYY'));
    const [usrsExtr, setUsrsExtr] = useState([]);
    const [segmento, setSegmento] = useState([]);
    const [esUsuarioInterno, setEsUsuarioInterno] = useState(false);
    const [noEditarFecha, setNoEditarFecha] = useState(true);
    const [userEstructuraAll, setUserEstructuraAll] = useState([]);
    const [openQuestion, setOpenQuestion] = useState(false);
    const [question, setQuestion] = useState('');
    const [checkSelectAll, setCheckSelectAll] = useState(false);
    const [entroReagendar, setEntroReagendar] = useState(false);
    const [openQuestEdit, setOpenQuestEdit] = useState(false);
    const [openQuestNoSeRlzo, setOpenQuestNoSeRlzo] = useState(false);
    const [mostrarAsistencia, setMostrarAsistencia] = useState(false);
    const [parametroAsistencia, setParametroAsistencia] = useState(false)

    //let todayFormat = moment().format('DD/MM/YYYY');
    let today = (new Date( moment().format('YYYY/MM/DD') ));

    useEffect(() => {
        //console.log("entroReagendar: "+entroReagendar)
    },[entroReagendar])
    useEffect(() => {
        setCompleted(true);
        let casoPocoCmun = false;
        let hstr = window.localStorage.getItem("titleToolbar");
        let usrsInvt = localStorage.getItem("usersEvent") === null? [] :
            JSON.parse(atob(localStorage.getItem("usersEvent")));
        //console.log(usrsInvt);
        // Tenemos que revisar los usuarios externos
        //Sacar el idu del usuario conectado para que no se auto invite
        setListaInvitados(usrsInvt.filter(invt=>invt.idu!==user.idu));
        //console.log("---> " + hstr);
        if( hstr.indexOf( '?add=true') >= 0 ){
            //setOpenModal(true);
            //handleClickOpenModal();
            setOpenModal(true);
            window.localStorage.setItem("titleToolbar","Calendario");
        }
        let eventosAdmin =  utils.eventosAll;
        setEventosAll(eventosAdmin);
        if(user.id_user_type_catalog===0 || user.rol.length===0){
            setEventosPorRol(eventosAdmin);
            AuthService.consult(`{users{
                    id
                    idu
                    nombre
                    apellido
                    correo
                    rol {
                      value
                      text
                    }
                    telefono
                    oficina: sucursal {
                      value
                      name
                    }
                }
            }`)
                .then(result=>{
                    setCompleted(true);
                    //console.log(result.data.data.estructura);
                    let estrAll = result.data.data.users;
                    if (estrAll.length>0){
                        //console.log(estrAll);
                        setUserEstructuraAll(estrAll.filter((el,i)=>el.idu!==null));
                    }
                })
                .catch(error=>{
                    setCompleted(true);
                    console.log(error);
                    if(error.response && error.response.status===401 && error.response.data.message==="Invalid Token"){
                        AuthService.logout();
                    }
                });
        } else {
            switch(user.rol[0].value){
                case 1: // presentadora
                    setEventosPorRol(utils.eventos.presentador);
                    break;
                case 3: // lider
                    setEventosPorRol(utils.eventos.lider);
                    break;
                case 5: // gerente
                    setEventosPorRol(utils.eventos.gerente);
                    break;
                case 8: // area manager
                    setEventosPorRol(utils.eventos.manager);
                    break;
                case 9: // regional manager
                    setEventosPorRol(utils.eventos.rgnlMngrYSS);
                    break;
                default:
                    setEventosPorRol(utils.eventos.presentador);
                    break;
            }

            //console.log(user);
            let iduEstructura="";
            switch (user.rol[0].value){
                case 3: // lider
                    iduEstructura=user.estructura.filter((el,i)=>el.rol[0].value===5);
                    break;
                case 5: // gerente - delegada
                    iduEstructura=user.estructura.filter((el,i)=>el.rol[0].value===8);
                    break;
                case 8: // area manager
                    iduEstructura=user.estructura.filter((el,i)=>el.rol[0].value===9);
                    break;
                case 9: // regional manager
                    iduEstructura=[user];
                    break;
                case 1: // presentadora
                    iduEstructura=user.estructura.filter((el,i)=>el.rol[0].value===3);
                    if(iduEstructura.length===0) {
                        iduEstructura=user.estructura.filter((el,i)=>el.rol[0].value===5);
                        if(iduEstructura.length>0){
                            let newIduEst = []
                            iduEstructura.map(est=> {
                                if(est.rol[0].text.toString().toLowerCase().indexOf('lider')>=0){
                                    est.rol[0].value = 3
                                    newIduEst.push(est)
                                }
                                return est
                            });
                            iduEstructura=newIduEst;
                        }
                        casoPocoCmun = true;
                    }
                    break;
                default:
                    iduEstructura=[user];
                    break;
            }
            //console.log(iduEstructura);
            if(iduEstructura.length===0) {
                iduEstructura = [user];
                alerts("Hay una inconsistencia con tu estructura, favor de revisar con tu Gerente.", true);
            }
            if(iduEstructura.length>0){
                AuthService.consult(`{estructura(iduSuperior:"${iduEstructura[0].idu}" idu: "${user.idu}" 
                        rol: ${user.rol[0].value}){
                    id
                    idu
                    nombre
                    apellido
                    correo
                    rol {
                      value
                      text
                    }
                    telefono
                    oficina: sucursal {
                      value
                      name
                    }
                  }
                }`)
                    .then(result=>{
                        //console.log(result.data.data.estructura);
                        //console.log("Arreglar user.estructura");
                        //console.log(user.estructura);
                        /*
                        let estrJrrq = [];
                        switch (user.rol[0].value){
                            case 3: // lider - Jefa de Grupo
                          //      console.log("Aqui entra...");
                                estrJrrq = user.estructura.filter((el,i)=> (el.rol[0].value!==8 && el.rol[0].value!==9) );
                                break;
                            case 5: // gerente - delegada
                                estrJrrq = user.estructura.filter((el,i)=> el.rol[0].value!==9);
                                break;
                            case 8: // area manager
                                estrJrrq = user.estructura.filter((el,i)=> el.rol[0].value===9);
                                break;
                            //case 9: // regional manager
                            //    iduEstructura=user;
                            //    break;
                            case 1: // presentadora
                                estrJrrq = user.estructura.filter((el,i)=> (el.rol[0].value!==5 && el.rol[0].value!==8 && el.rol[0].value!==9));
                                break;
                            default:
                                estrJrrq = user.estructura.filter((el,i)=> (el.rol[0].value!==5 && el.rol[0].value!==8 && el.rol[0].value!==9));
                                break;
                        }
                        //console.log(estrJrrq);
                        */

                        //let estrAll = (result.data.data.estructura && result.data.data.estructura.length>0 ? result.data.data.estructura:[]); //[].concat(estrJrrq.filter((el,i)=>el.idu!==user.idu), result.data.data.estructura);
                        let estrAll = casoPocoCmun ?
                            result.data.data.estructura.filter(usr=>
                                (usr.idu===iduEstructura[0].idu) ||
                                (usr.estructura && usr.estructura.filter(otE =>
                                    otE.rol[0].text.toString().toLowerCase().indexOf("lider")>=0 &&
                                    iduEstructura[0].correo && otE.idu===iduEstructura[0].idu
                                ).length>0)
                            ):
                            (result.data.data.estructura && result.data.data.estructura.length>0 ? result.data.data.estructura:[]);
                        if (estrAll.length>0){
                            //console.log(estrAll);
                            setUserEstructuraAll(estrAll);
                        }
                    })
                    .catch(error=>{
                        console.log(error);
                        if(error.response && error.response.status===401 && error.response.data.message==="Invalid Token"){
                            AuthService.logout();
                        }
                    });
            }
        }
        localStorage.removeItem("usersEvent");
        consultaEventos(user.rol[0].value === 0 ? user.correo: user.idu, today);
        consultaUsuariosExternos(user.rol[0].value === 0 ? user.correo: user.idu);
    }, []);
    /**
     * Función para mostrar notificación al usuario
     * @param {*} msg Mensaje que se le mostrara al usuario
     * @param {*} error Para saber si corresponde a un error
     */
    const alerts = (msg, error) =>{
        toast(msg, {
            className: css({
                background: error ? "#E6332A":"#00AC46",
                color: "white"
            }),
            progressClassName: css({
                background: error ? "#00AC46":"#FFF"
            })
        });
    };
    /**
     * Función para traer a todos los usuarios externos
     * @param {*} correo Correo ligado a los usuarios externos 
     */
    const consultaUsuariosExternos = (correo) => {
        AuthService.consult(`{userExternos(iduQuienCreo:"${correo}"){
            id idu: id nombre correo telefono categoria activo perfil  noPedido PedidoID referenciadoLMS NetSuiteID ETRealizada ETReportada}}`)
            .then(result=>{
                //console.log(result);
                let arrSegmento = [];
                result.data.data.userExternos.map((user, j)=>
                    user.perfil.forEach((el, i)=> {
                        if (!arrSegmento.includes(el)) {
                            arrSegmento.push(el);
                        }
                    })
                );
                //console.log(arrSegmento);
                setSegmento(arrSegmento);
                //To Do VerZ2: setUsrsExtr User Externos data recieved.
                setUsrsExtr(result.data.data.userExternos)
            })
            .catch(error=>{
                console.log(error);
            })
    };
    /**
     * Función para consultar los eventos
     * @param {*} correo Filtro por correo
     * @param {*} fecha Filtro por fecha
     */
    const consultaEventos = (correo, fecha) => {
        // NOTA IMPORTANTE PARA NO CONFUNIDR EN EL FUTURO
        // EL CORREO ES EN CASO DE QUE SEA ADMIN, EN CASO CONTRARIO MANDA EL IDU DEL USUARIO DE NETSUITE
        // console.log(correo + " Vamos a consultar esta fecha para eventos del día: " + fecha);
        setFechaTitle(moment(fecha).format('DD/MM/YYYY'));
        //let todayF = moment(start).format('DD/MM/YYYY');
        let tdy = new Date(moment(fecha).format('YYYY/MM/DD'));

        //todayFormat=(todayF);
        today=(tdy);

        //console.log(start)
        setDefaultDate(new Date(fecha));
        //To Do Verz2: Check new params on this Variable can't be added.
        let datos = `id titulo fecha start end color idTipo tipo modalidad1 modalidad2 notas direccion idAppointment 
             listaInvitados { idu nombre correo segmento esExtr sendMail asistire referenciadoLMS} status created_event iduPresentador 
             correoPresentador nombrePresentador seRepite frecuencia seRepiteHasta idEventoLigado fechaEvento horaInicioEvento 
            fechaReagenda fechaReagendaFormato horaInicioReagenda`;
        // console.log("**********");
        // console.log(today);
        // console.log("**********");
        AuthService.consult(`{ parametro(idParametro: 1 ){ id idParametro parametro descripcion valor user_create created_user }
            eventByMonth( correo: "${correo}", fecha: "${fecha}" ){ ${datos} },
            eventByDate( correo: "${correo}", fecha: "${fecha}" ){ ${datos} }}`)
            .then(result=>{
                let arrEvnt = [];
                if(result.data.data.parametro.length>0 && result.data.data.parametro[0].valor==='true'){
                  setParametroAsistencia(true)
                } else {
                  setParametroAsistencia(false)
                }
                result.data.data.eventByMonth.forEach((evento, i)=>{
                    if(evento.status>0){
                        let evnt = {
                            id: evento.id,
                            titulo: evento.titulo,
                            fecha: evento.fecha,
                            start: new Date(evento.start),
                            end: new Date(evento.end),
                            color: evento.color,
                            idTipo: evento.idTipo,
                            tipo: evento.tipo,
                            modalidad1: evento.modalidad1,
                            modalidad2: evento.modalidad2,
                            notas: evento.notas,
                            direccion: evento.direccion,
                            listaInvitados: evento.listaInvitados,
                            iduPresentador: evento.iduPresentador,
                            correoPresentador: evento.correoPresentador,
                            nombrePresentador: evento.nombrePresentador,
                            seRepite: evento.seRepite,
                            frecuencia: evento.frecuencia,       // 1 Semanal, 2 Mensual, 3 Anual
                            seRepiteHasta: evento.seRepiteHasta,
                            idEventoLigado: evento.idEventoLigado,
                            status: evento.status,  // 1 - Nuevo evento, 0 - Evento cancelado, 2 - Evento re agendado
                            fechaReagenda: evento.fechaReagenda,
                            fechaReagendaFormato: evento.fechaReagendaFormato,
                            horaInicioReagenda: evento.horaInicioReagenda
                        };
                        arrEvnt.push(evnt);
                    }
                });
                setEventsMonth(arrEvnt);
                // Vamos a colocar los segmentos unicos por evento
                let eventosDia = [];
                result.data.data.eventByDate.forEach((evntDia,i)=>{
                    if(evntDia.status>0) {
                        evntDia.fixStart = evntDia.start;
                        let arrSegmento = "";
                        evntDia.listaInvitados.forEach((user, j) => {
                            arrSegmento += '"' + user.segmento + '"';
                        });
                        arrSegmento = arrSegmento.replace(/""/g, '","').replace(/"/g, "");
                        let arrSgmn = arrSegmento.indexOf(",") >= 0 ? arrSegmento.split(",") : [arrSegmento];
                        let sgmnUnco = [];
                        arrSgmn.length > 0 && arrSgmn.forEach((sgmn, i) => {
                            if (sgmn !== 'undefined' && sgmn.length > 0 && !sgmnUnco.includes(sgmn)) {
                                sgmnUnco.push(sgmn)
                            }
                        });
                        eventosDia.push({...evntDia, seguimientoUnico: sgmnUnco});
                    }
                });
                setEventsDay(eventosDia);
            })
            .catch(error=>{
                console.log("Error eventByMonth, eventByDate: " + error);
            })
    };
    /**
     * Función para seleccionar un día en el calendario y consultar los eventos de ese día
     * @param {String} start Fecha de inicio
     * @param {String} end Fecha de fin
     */
    const handleClickSelectDay = ({start, end}) => {
        setEventsDay([]);
        //console.log("Vamos por todos los eventos de este día: " + start);
        consultaEventos(user.rol[0].value === 0 ? user.correo: user.idu, start);

        //consultaEventos(user.correo, today);
    };
    /**
     * Función para marcar el evento como frecuente
     * @param {*} event Cambio de valor en el elemento
     */
    const handleChangeSeRepite = (event) => {
        //To Do Ver3: Add the other type of events that can not be repetitive.
        if(idTipoEvento==='1' || idTipoEvento==='2'){
            //To Do Ver8: Remove this console logs.
            console.log('Se anula repeat por tipo de evento.')
            setEventoRepetable(false);
            setSeRepite(false);
        } else {
            console.log('Se define repeat por estado del check.');
            setSeRepite(event.target.checked)
        }       
    };
    /**
     * Función para detectar los datos que estan cambiando de valor al editarlos
     * @param {*} event Cambio de valor en el elemento
     */
    const handleChangeDatosEvento = (event) => {

        const target = event.target;
        //const value = target.type === 'checkbox' ? target.checked : target.value;
        //const name = target.name;

        //console.log(value);
        //console.log(name);

        //To Do Ver6: remove this functions for special characters if enabled in the save function
        /*
        if (datosEvento.titulo.includes('"')) {
            datosEvento.fixtitulo = datosEvento.titulo.replaceAll('"','');
            //To Do Ver3: Remove this log and improve this anti spec chars function....
            console.log('Se encontraron caracteres especiales en el evento. Reemplazando...')
          } else {
            datosEvento.fixtitulo = datosEvento.titulo;
            console.log('Sin caracteres especiales.')
          }
          */

        setDatosEvento({ ...datosEvento, [target.name]: target.value });
        if(target.name === 'start'){
            let arrHraAtmt =  target.value.split(":");
            let hraAtmt = arrHraAtmt[0]==='23'?'00':(parseInt(arrHraAtmt[0])+1)
            //console.log(hraAtmt + " - " + hraAtmt.toString().length)
            hraAtmt = (hraAtmt.toString().length===1?'0'+hraAtmt.toString():hraAtmt);
            //console.log("--> "+ hraAtmt + " ----->> " + (hraAtmt+":"+arrHraAtmt[1]) )
            //setDatosEvento({ ...datosEvento, "end": hraAtmt+":"+arrHraAtmt[1] });
            setDatosEvento({ ...datosEvento, "start": target.value, "end": hraAtmt+":"+arrHraAtmt[1] });
            //console.log(ev.target.value)
        }
    };
    /**
     * Función para seleccionar el tipo de evento
     * @param {*} ev Cambio de valor en el elemento
     */
    const handleChangeTipoEvento = (ev) => {
        //console.log(ev.target.value);
        let evnt = eventosPorRol.filter((el,i)=>el.idTipo===parseInt(ev.target.value));
        //console.log(evnt);
        if(evnt.length>0){
            setModalidad1(evnt[0].modalidad1);
            setModalidad2(evnt[0].modalidad2);
            setEditaTitulo(evnt[0].editaTitulo);
            setPeriodicidad(evnt[0].periodicidad);
            setTipoEvento(evnt[0].nombre);
            setIdTipoEvento(ev.target.value);
            console.log('Valor ev.target.value: '+ev.target.value);
            if(ev.target.value==='1' || ev.target.value==='2'){
                //To Do Ver8: Remove this console logs.
                console.log('Tipo Evento NO repetible seleccionado.')
                setEventoRepetable(false);
            } else {
                //To Do Ver8: Remove this console logs.
                console.log('Tipo Evento repetible seleccionado.')
                setEventoRepetable(true);
            }
        } else {
            setModalidad1(false);
            setModalidad2(false);
            setEditaTitulo(false);
            setPeriodicidad(false);
            setTipoEvento("");
            setIdTipoEvento("0");
            setEventoRepetable(false);
        }
    };
    const moveEvent = ({ event, start, end, isAllDay: droppedOnAllDaySlot }) => {
        console.log(event);
        console.log(start);
        console.log(end);
    };
    const resizeEvent = ({ event, start, end }) => {
        console.log(event);
        console.log(start);
        console.log(end);
    };
    /**
     * Función para seleccionar un evento
     * @param {*} event Datos del evento
     */
    const selectEvent = (event) => {
        console.log("Vamos a editar el evento: LINE 683");
        console.log(event);
        let fechaInicia = new Date(event.start);
        handleClickSelectDay({start: new Date(fechaInicia.getFullYear(), fechaInicia.getMonth(), fechaInicia.getDate()), end: new Date(event.end)});
        /*
        let eventoSlcn = ({...event,
            created_event: event.fecha,
            fechaEvento: new Date(event.fecha).toLocaleDateString('es-MX', { year: 'numeric', month: '2-digit', day: '2-digit' }),
            horaInicioEvento: new Date(event.fecha).toLocaleTimeString('es-MX', { hour: '2-digit', minute: '2-digit'}),
            idAppointment: ""
        });
        eventoSlcn.start = new Date(event.start).toLocaleTimeString('es-MX', { hour: '2-digit', minute: '2-digit'});
        eventoSlcn.end = new Date(event.end).toLocaleTimeString('es-MX', { hour: '2-digit', minute: '2-digit'});
        handleClickOpenEditaModal(eventoSlcn);
        */
    };

    const doubleClickEvent = (event) => {
        console.log("Eliminar id:_ " + event.id);
    };

    let formats = {
        // selectRangeFormat: '8:00am — 2:00pm',
        timeGutterFormat: 'HH:mm',

        eventTimeRangeFormat: ({start,end}, culture, local) =>
            local.format(start, 'HH:mm', 'es-mx') + '-' +
            local.format(end, 'HH:mm', 'es-mx'),

        //dayFormat: 'dddd',
        dayFormat: (date,c) => {
            var dia="";
            var arrDia = date.toString().split(" ");

            if(date.toString().indexOf("Mon") > -1) { dia= arrDia[2] + " Lun" }
            if(date.toString().indexOf("Tue") > -1) { dia= arrDia[2] + " Mar" }
            if(date.toString().indexOf("Wed") > -1) { dia= arrDia[2] + " Mié" }
            if(date.toString().indexOf("Thu") > -1) { dia= arrDia[2] + " Jue" }
            if(date.toString().indexOf("Fri") > -1) { dia= arrDia[2] + " Vie" }
            if(date.toString().indexOf("Sat") > -1) { dia= arrDia[2] + " Sáb" }
            if(date.toString().indexOf("Sun") > -1) { dia= arrDia[2] + " Dom" }
            return dia
        },
        weekdayFormat: (date,c) => {
            var dia="";

            if(date.toString().indexOf("Mon") > -1) { dia="Lunes" }
            if(date.toString().indexOf("Tue") > -1) { dia="Martes" }
            if(date.toString().indexOf("Wed") > -1) { dia="Miércoles" }
            if(date.toString().indexOf("Thu") > -1) { dia="Jueves" }
            if(date.toString().indexOf("Fri") > -1) { dia="Viernes" }
            if(date.toString().indexOf("Sat") > -1) { dia="Sábado" }
            if(date.toString().indexOf("Sun") > -1) { dia="Domingo" }
            return dia
        },
        monthHeaderFormat: (date,c) => {
            var mes=getMes(date);
            return mes
        },
        dayHeaderFormat: (date,c) => {
            var arrDia = date.toString().split(" ");
            var mes=getMes(date);
            var dia="";

            if(date.toString().indexOf("Mon") > -1) { dia="Lun" }
            if(date.toString().indexOf("Tue") > -1) { dia="Mar" }
            if(date.toString().indexOf("Wed") > -1) { dia="Mié" }
            if(date.toString().indexOf("Thu") > -1) { dia="Jue" }
            if(date.toString().indexOf("Fri") > -1) { dia="Vie" }
            if(date.toString().indexOf("Sat") > -1) { dia="Sáb" }
            if(date.toString().indexOf("Sun") > -1) { dia="Dom" }
            return dia+" "+arrDia[2]+" "+mes
        },
        dayRangeHeaderFormat: ({ start, end },c) => {
            let arrDiaI = start.toString().split(" ");
            let arrDiaF = end.toString().split(" ");
            let inicia = getMes(start);
            let finaliza = getMes(end);
            let mes="";
            if(inicia===finaliza){
                mes = inicia +" " +arrDiaI[2] + " - " + arrDiaF[2];
            } else {
                mes = arrDiaI[2] + " " + inicia + " - " + arrDiaF[2]+ " " + finaliza;
            }
            return mes
        },



        agendaTimeRangeFormat: ({
                                    start,
                                    end
                                }, culture, local) =>
            local.format(start, 'HH:mm', 'es-mx') + '-' +
            local.format(end, 'HH:mm', 'es-mx'),
        agendaDateFormat: 'DD/MM ddd',

    };
    function getMes(date){
        var dia="";
        if(date.toString().indexOf("Jan") > -1) { dia="Enero" }
        if(date.toString().indexOf("Feb") > -1) { dia="Febrero" }
        if(date.toString().indexOf("Mar") > -1) { dia="Marzo" }
        if(date.toString().indexOf("Apr") > -1) { dia="Abril" }
        if(date.toString().indexOf("May") > -1) { dia="Mayo" }
        if(date.toString().indexOf("Jun") > -1) { dia="Junio" }
        if(date.toString().indexOf("Jul") > -1) { dia="Julio" }
        if(date.toString().indexOf("Aug") > -1) { dia="Agosto" }
        if(date.toString().indexOf("Sep") > -1) { dia="Septiembre" }
        if(date.toString().indexOf("Oct") > -1) { dia="Octubre" }
        if(date.toString().indexOf("Nov") > -1) { dia="Noviembre" }
        if(date.toString().indexOf("Dec") > -1) { dia="Diciembre" }
        return dia
    }
    const messages = {
        allDay: 'Todo el día',
        previous: 'Anterior',
        next: 'Siguiente',
        today: 'Hoy',
        month: 'Mes',
        week: 'Semana',
        day: 'Día',
        agenda: 'Listado',
        date: 'Fecha',
        time: 'Horario',
        event: 'Evento',
        noEventsInRange: 'No hay eventos en este rango.',
        showMore: total => `+ Ver Todo (${total})`
    };
    //const today = new Date();
    const DialogTitle = withStyles(styles)((props) => {
        const { children, classes, onClose, ...other } = props;
        return (
            <MuiDialogTitle disableTypography className={classes.root} {...other}>
                <Typography variant="h6">{children}</Typography>
                {onClose ? (
                    <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                        <CloseIcon />
                    </IconButton>
                ) : null}
            </MuiDialogTitle>
        );
    });
    /**
     * Función para modificar la fecha del evento
     * @param {*} date Nueva Fecha del evento
     */
    const handleChangeEventDate = (date) =>{
        //console.log(date);
        setEventDate(date);
    };
    /**
     * Función para colocar la fecha hasta cuando será frecuente un evento
     * @param {*} date Fecha de frecuencia
     */
    const handleChangeEventDateHasta = (date) =>{
        //console.log(date);
        setEventDateHasta(date);
    };
    /*
    const handleClickOpenModal = () => {
        setEventDate(new Date());
        setDatosEvento(eventoNuevo);
        setListaInvitados([]);
        setOpenModal(true);
    };
    */
    
    /**
     * Función para abrir modal para agregar/editar un evento
     * @param {*} evnt Datos del evento
     */
    const handleClickOpenEditaModal = (evnt) => {
        //To Do VerZ5: Here must go the modification to fix list pass error for future events.
        console.log('---------> Evento que se va a editar: ');
        if(evnt.checkpoint !== true){
            evnt.checkpoint = true;
            evnt.checkpointstart = evnt.start;
            evnt.checkpointend = evnt.end;
        }
        
        console.log(evnt);
        
        //let editEvent = new Date(evnt.checkpointend).getTime()>new Date().getTime();
        let editEvent = new Date(evnt.checkpointstart).getTime()>new Date().getTime();
        //To Do Ver9: Verify correcctions of this secction.
        console.log('Fecha de evento para comparación: '+new Date(evnt.start));
        console.log('Fecha actual para la comparación: '+new Date());
        function prettyJSONv(obj) {
            let stringed = JSON.stringify(obj, null, 2);
            return stringed
        }
        let evntJson = prettyJSONv(evnt);
        console.log('Params de evnt: '+evntJson);
        console.log('Valor de editEvent: '+editEvent);
        //console.log("Fecha de inicio del evento: " + editEvent);
        setPersonas([]);
        let evntEdtr = eventosAll.filter((el,i)=>el.idTipo===parseInt(evnt.idTipo));
        //console.log(evntEdtr);
        if(evntEdtr.length>0){
            setModalidad1(evntEdtr[0].modalidad1);
            setModalidad2(evntEdtr[0].modalidad2);
            setEditaTitulo(evntEdtr[0].editaTitulo);
            setPeriodicidad(evntEdtr[0].periodicidad);
            setTipoEvento(evntEdtr[0].nombre);
            setIdTipoEvento(evntEdtr[0].idTipo);
        }
        setEventDate(new Date(evnt.fecha));
        setEventDateHasta(new Date(evnt.seRepiteHasta));
        setStateSwitch({ ...stateSwitch, checkedModalidad1: (evnt.modalidad1==="party"),
            checkedModalidad2: (evnt.modalidad2==="virtual") });
            
        if(evnt.start.length>5){
            evnt.start = (new Date(evnt.start).getHours()<10?'0'+new Date(evnt.start).getHours().toString():new Date(evnt.start).getHours())+':'
                +(new Date(evnt.start).getMinutes()<10?'0'+new Date(evnt.start).getMinutes().toString():new Date(evnt.start).getMinutes());
        }
        if(evnt.end.length>5) {
            evnt.end = (new Date(evnt.end).getHours() < 10 ? '0' + new Date(evnt.end).getHours().toString() : new Date(evnt.end).getHours()) + ':'
                + (new Date(evnt.end).getMinutes() < 10 ? '0' + new Date(evnt.end).getMinutes().toString() : new Date(evnt.end).getMinutes());
        }
        
        setSeRepite(evnt.seRepite);

        setDatosEvento(evnt);
        //Sacar el idu del usuario conectado para que no se auto invite
        //console.log(evnt)
        // Esta bien que no se ve a si mismo pero tenemos que ver validar si no es su evento si se debe de ver
        if(evnt.iduPresentador === user.idu){
            setListaInvitados(evnt.listaInvitados.filter(invt=>invt.idu!==user.idu));
        } else {
            setListaInvitados(evnt.listaInvitados);
        }
        // Si el evento aun no ocurre debe poder entrar a editarlo directamente o si no es su evento
        //To Do Ver9: Changes for correct function on dev of Edit Event.
        //editEvent = false;
        if(editEvent){
            setOpenModal(true);
            setOpenQuestEdit(false);
        } else { // si el evento ya paso preguntar si quieren si quiere ver los datos o tomar asistencia
            if(evnt.iduPresentador !== user.idu){
                console.log('Valor Recibido From event: '+evnt.iduPresentador);
                console.log('Valor Recibido From user.idu: '+user.idu);
                setOpenModal(true);
                setOpenQuestEdit(false);
            } else {
                setOpenQuestEdit(true);
                setOpenModal(false);
                validateMonday();
            }
        }
    };
    /**
     * Funcion para cerrar modal
     * @param {*} ev Cambio de valor en el elemento
     */
    const handleCloseModal = (ev) =>{
        setOpenModal(false);
        setNoEditarFecha(true);
        //validateMonday();
        //setOpenQuestEdit(false);
        //setMostrarAsistencia(false);
        console.log('Close Button pressed.');
    };
    /**
     * Función para modificar valor en switch
     * @param {*} ev 
     */
    const handleChangeSwitch = (ev) => {
        setStateSwitch({ ...stateSwitch, [ev.target.name]: ev.target.checked });
    };
    /**
     * Función para filtrar entre usuarios externos
     * @param {*} sgmn Segmento para filtrar
     * @param {*} title Título del segmento
     */
    const filtrarExternos = (sgmn, title) => {
        setEsUsuarioInterno(false);
        setCheckSelectAll(false);
        let arrPrsn = [];
        if(sgmn!==""){
            usrsExtr.forEach((usrs, index)=>{
                if(usrs.perfil.includes(sgmn)){
                    if( !arrPrsn.includes(usrs) ){
                        arrPrsn.push(usrs)
                    }
                }
            });
        } else {
            arrPrsn = usrsExtr
        }
        let sortPersonas = arrPrsn.sort((a,b) => a.nombre.toLowerCase() > b.nombre.toLowerCase()? 1:
            a.nombre.toLowerCase() < b.nombre.toLowerCase()? -1:0)
        setPersonas(sortPersonas);
        setTitlePersonas(title);
    };
    /**
     * Función para filtrar entre usuarios internos
     * @param {*} idRol ID del rol para realizar filtro
     * @param {*} title Título del rol ques e esta filtrando
     */
    const filtrarInternos = (idRol, title) => {
        setEsUsuarioInterno(true);
        setCheckSelectAll(false);
        let arrPrsn = [];
        if(idRol>=0){
            userEstructuraAll.forEach((intr, index)=>{
                if(intr.rol[0].value===idRol){
                    //console.log(intr)
                    if( !arrPrsn.includes(intr) ){
                        arrPrsn.push(intr)
                    }
                }
            });
        } else {
            arrPrsn = userEstructuraAll
        }
        let sortPersonas = arrPrsn.sort((a,b) => a.nombre.toLowerCase() > b.nombre.toLowerCase()? 1:
            a.nombre.toLowerCase() < b.nombre.toLowerCase()? -1:0)
        console.log(sortPersonas)
        setPersonas(sortPersonas);
        setTitlePersonas(title);
    };
    /*
    const agregarInvitado = (prsn) => {
        let invitado = {idu: "", nombre: "", correo: "", segmento: []};
        if(esUsuarioInterno){
            //Es un interno tiene idu, revisar que no este en la lista de invitados
            let agrgInvt = true;
            listaInvitados.map((invt, i)=>{
                if(invt.idu===prsn.idu){
                    agrgInvt = false;
                }
            });
            if(agrgInvt){
                invitado.idu = prsn.idu;
                invitado.nombre = prsn.nombre + " " + (prsn.apellido && prsn.apellido !==null && prsn.apellido.length>0? prsn.apellido: '');
                invitado.correo = prsn.correo;
                invitado.segmento = "";
            }
        } else{
            //Es un externo, guardar en el idu su id, revisar que no este en la lista de invitados
            let agrgInvt = true;
            listaInvitados.map((invt, i)=>{
                if(invt.idu===prsn.id){
                    agrgInvt = false;
                }
            });
            if(agrgInvt) {
                invitado.idu = prsn.id;
                invitado.nombre = prsn.nombre;
                invitado.correo = prsn.correo;
                invitado.segmento = prsn.perfil.toString();
            }
        }
        if(invitado.idu!==''){
            setListaInvitados(listaInvitados.concat(invitado))
        }
    };
    */
    /**
     * Función para quitar a un invitado
     * @param {*} prsn Datos del invitado
     */
    const quitarInvitado = (prsn) => {
        let newListaInvitados = listaInvitados.filter((p,i)=>p.idu!==prsn.idu);
        setListaInvitados(newListaInvitados);
    };
    const handleChangeAutoCmpl = (event, value) => {
        //console.log(value)
        //console.log("revisar la lista de invitados, que no se repita alguno y que no me elimine los que ya estan");
        let newInvt = Object.assign([] , listaInvitados);
        //console.log(newInvt)
        value.forEach((el,i)=>{
            //  console.log(el)
            let newArr = newInvt.filter((item, j)=> el.rol && el.rol[0].value===0 ? item.correo===el.correo: item.idu===el.idu);
            //console.log("Existe: ")
            //console.log(newArr)
            if(newArr.length===0){
                let obNew = esUsuarioInterno ?
                    {
                        idu: el.idu.length>0 ? el.idu: el.correo,
                        nombre: el.nombre + ' ' + (el.apellido && el.apellido!==null && el.apellido.length>0 ? el.apellido: ''),
                        correo: el.correo, segmento: [], esExtr: false, referenciadoLMS: false
                    } :
                    {
                        idu:el.idu, nombre: el.nombre, correo: el.correo, segmento: el.perfil, esExtr: true, referenciadoLMS: el.referenciadoLMS, ETRealizada: el.ETRealizada
                    };
                    //To Do VerZ2: Test for adding Recomienda y Gana info for NetSuite.
                console.log('NetSuite Tests: El Nombre del invitado es: '+el.nombre);
                console.log('NetSuite Tests: El correo del invitado es: '+el.correo);
                //To Do VerZ2: New RYG integration values are not been recieved here.
                if (esUsuarioInterno){
                    console.log('Caso: UserInterno ---> Es Referenciado RYG: AUTO-FALSE');
                } else {
                    console.log('Caso: UserExt ---> Es Referenciado RYG: '+el.referenciadoLMS);
                }
                
                newInvt.push(obNew)
            }
        });
        //console.log(newInvt)
        //Sacar el idu del usuario conectado para que no se auto invite
        setListaInvitados(newInvt.filter(invt=>invt.idu!==user.idu));
    };
    /**
     * Función para agregar un nuevo evento
     */
    const newEvent = () => {{
        //To Do Ver9: Confirm if disabling this works fine.
        //setCompleted(false);
        setSaveBtnState(true);
        let horaInicioEvento = datosEvento.start;
        let horaFinEvento = datosEvento.end;

        let continua = true;
        let mensaje = "";
        let start = new Date(
            eventDate.getFullYear(),
            eventDate.getMonth(),
            eventDate.getDate(),
            datosEvento.start.split(":")[0],
            datosEvento.start.split(":")[1]
        );
        if(start.getTime()<new Date().getTime()){
            continua = false;
            mensaje = "No puedes seleccionar un horario anterior al actual.";
        }
        let end = new Date(
            eventDate.getFullYear(),
            eventDate.getMonth(),
            eventDate.getDate(),
            datosEvento.end.split(":")[0],
            datosEvento.end.split(":")[1]
        );

        if(idTipoEvento==='0'){
            continua=false;
            mensaje = "Selecciona un tipo de evento";
        }
        if(idTipoEvento==='1' || idTipoEvento==='2'){
            //To Do Ver8: Remove this console logs.
            console.log('Se anula repeat por tipo de evento en proceso de guardado.')
            setEventoRepetable(false)
            setSeRepite(false)
        }
        if(listaInvitados.length===0 && idTipoEvento!=='5'){
            continua = false;
            mensaje = "Agrega un invitado por lo menos a tu evento"
        }
        let CheckExternos = false;
        listaInvitados.forEach((invt, i)=>{
            if(invt.esExtr){
                console.log('Invitado Externo econtrado!');
                CheckExternos = true;
            }
        });
        if(idTipoEvento==='1' && CheckExternos===false){
            //To Do Ver8: Remove this console logs.
            console.log('No Hay Externos, Detener Guardado.')
            continua = false;
            mensaje = "Debes agregar al menos un usuario externo para este tipo de evento."
        }
        if(idTipoEvento==='2' && CheckExternos===false){
            //To Do Ver8: Remove this console logs.
            console.log('No Hay Externos, Detener Guardado.')
            continua = false;
            mensaje = "Debes agregar un usuario externo para este tipo de evento."
        }
        if(continua){
            if (datosEvento.titulo.includes('"')) {
                datosEvento.fixtitulo = datosEvento.titulo.replaceAll('"','');
                //To Do Ver3: Remove this log and improve this anti spec chars function....
                console.log('Se encontraron caracteres especiales en el evento. Reemplazando...')
              } else {
                datosEvento.fixtitulo = datosEvento.titulo;
                console.log('Sin caracteres especiales.')
              }
            let obEv = {
                titulo: datosEvento.fixtitulo,
                fecha: eventDate,
                start: new Date(start.getTime()),
                end: new Date(end.getTime()),
                color: datosEvento.color,
                idTipo: idTipoEvento,
                tipo: tipoEvento,
                modalidad1: stateSwitch.checkedModalidad1 ? 'party': 'individual',
                modalidad2: stateSwitch.checkedModalidad2 ? 'virtual': 'presencial',
                notas: datosEvento.notas.replace(/\n/g," ").replace(/"/g, "").replace(/'/g, ""),
                direccion: datosEvento.direccion,
                listaInvitados: listaInvitados,
                iduPresentador: (user.rol[0].value === 0 ? user.correo: user.idu),
                correoPresentador: user.correo,
                nombrePresentador: user.nombre + (user.apellido && user.apellido.length>0? ' ' + user.apellido: ''),
                seRepite: seRepite,
                frecuencia: datosEvento.frecuencia,
                seRepiteHasta: eventDateHasta,
                status: 1,
                horaInicioEvento: horaInicioEvento,
                horaFinEvento: horaFinEvento
            };
            if(idTipoEvento==='1' || idTipoEvento==='2'){
                obEv.seRepite = false;
                obEv.frecuencia = 0;
            }
            //console.log(listaInvitados)
            let prepareListInvit = "[";
            listaInvitados.forEach((invt, i)=>{
                prepareListInvit += '{idu: "'+ invt.idu + '", nombre: "'+ invt.nombre +
                    (invt.apellido && invt.apellido!==null && invt.apellido.length>0 ? ' ' + invt.apellido: '') +
                    '", correo: "'+invt.correo+'", segmento: "'+invt.segmento+'", esExtr: '+invt.esExtr+', sendMail: '+
                    false+', asistire: 0, referenciadoLMS: '+invt.referenciadoLMS+'}';
                    //To Do VerZ1: Delete this console log.
                    console.log('----------El Valor para referenciado sería :'+invt.referenciadoLMS);
                if(invt.esExtr){
                    let userExtrCtgr = usrsExtr.filter(usr => usr.id===invt.idu);
                    if(userExtrCtgr && (parseInt(userExtrCtgr[0].categoria)===1 || parseInt(userExtrCtgr[0].categoria)===2)){
                        // Revisar si ya tiene un estatus == 3 a > a 3 no hacer el cambio
                        AuthService.consult('mutation{ ' +
                            '  updateCategoriaUsroExtr(id: "'+invt.idu+'", categoria:"3"){ ' +
                            '    id categoria ' +
                            '  } ' +
                            '}')
                            .then(res=>{
                                //console.log(res)
                            }).catch(error=>{
                            //console.log(error)
                        })
                    }
                }
            });
            prepareListInvit += "]";
            //console.log(prepareListInvit);
            let finAnio = eventDateHasta;   // new Date(obEv.fecha.getFullYear(), 12, 0);
            if(obEv.frecuencia==='3'){
                finAnio = new Date(obEv.fecha.getFullYear()+1, 12, 0);
            }

            let fechaIncr = new Date(
                eventDate.getFullYear(),
                eventDate.getMonth(),
                eventDate.getDate(),
                datosEvento.start.split(":")[0],
                datosEvento.start.split(":")[1]
            );

            let idEventoLigado = "";
            //To Do VerZ2: Add MongoLog to this errors and others....
            async function addEvent(gql) {
                //console.log(gql)
                return await AuthService.consult(gql.replace("\n"," "))
                    .then(async result => {
                        setCompleted(true);
                        //console.log(result.data.data.addEvent);
                        setEventsDay([]);
                        consultaEventos(user.rol[0].value === 0 ? user.correo: user.idu, today)
                        setOpenModal(false);
                        setNoEditarFecha(true);
                        return result.data.data.addEvent
                    })
                    .catch(error => {
                        setCompleted(true);
                        console.log("error addEvent info: " + error);
                        setEventsDay([]);
                        consultaEventos(user.rol[0].value === 0 ? user.correo: user.idu, today)
                        setOpenModal(false);
                        setNoEditarFecha(true);
                        return {}
                    });
            }

            let query = prepareGqlNewEvent(fechaIncr, prepareListInvit, obEv, idEventoLigado);
            addEvent(query).then(resp=>{
                //console.log(resp.id);
                alerts("Se ha creado el evento", false);
                    listaInvitados.forEach(invt => {
                        console.log("invitado externo: " + invt.esExtr)
                        if(!invt.esExtr) {
                            let ntfcSckt = Object.assign(resp, {
                                reagendado: false,
                                idu: invt.idu,
                                correoInvitado: invt.correo,
                                nombrePresentador: user.nombre + ' ' + (user.apellido && user.apellido.length > 0 ? user.apellido : ''),
                                msg: "Te han invitado al evento: " + tipoEvento + ", para el día " +
                                start.toLocaleDateString() + " a las " + start.toLocaleTimeString()
                            })
                            //let ntfcSckt = Object.assign(resp, ntfcSckt)
                            console.log(ntfcSckt);
                            socket.emit("requestNtfcUser", ntfcSckt)
                        }
                    });
                    enviarCorreoInvitacion(listaInvitados, tipoEvento, resp.id);

                idEventoLigado = resp.id;
                // Meter los eventos que se repiten mientras la fecha sea menor a la que hayan elegido los usuario
                while(fechaIncr<finAnio) {
                    //console.log(query);
                    switch(obEv.frecuencia){
                        case "1":  // Sumar 7 dias
                            fechaIncr.setDate(fechaIncr.getDate()+7);
                            break;
                        case "2":  // Sumar meses
                            fechaIncr.setMonth(fechaIncr.getMonth()+1);
                            break;
                        case "3":  // Sumar 1 año
                            fechaIncr.setFullYear(fechaIncr.getFullYear()+1);
                            break;
                        default:
                            finAnio = fechaIncr;
                            break;
                    }
                    if(fechaIncr<finAnio){
                        let query = prepareGqlNewEvent(fechaIncr, prepareListInvit, obEv, idEventoLigado);
                        addEvent(query);
                    }
                }

            })
                .catch(error=>{
                    console.log(error);
                });

            // obEv.fecha < finAnio
            //console.log(finAnio);
        } else {
            //To Do Ver9: Reactivate this function.
            alerts(mensaje, true);
            setSaveBtnState(false);
        }
    }};
    /**
     * Función para preparar el gql para un nuevo evento
     * @param {*} fechaIncr Fecha del evento
     * @param {*} prepareListInvit Lista de invitados
     * @param {*} obEv Datos del evento
     * @param {*} idEventoLigado ID evento padre
     * @returns Regresa el gql para agregar un nuevo evento
     */
    const prepareGqlNewEvent = (fechaIncr, prepareListInvit, obEv, idEventoLigado) => {
        let fechaEvento = fechaIncr.toLocaleDateString('es-MX', { year: 'numeric', month: '2-digit', day: '2-digit' });
        let gql = `mutation{
                addEvent(
                        titulo: "${obEv.titulo}",
                        fecha: "${fechaIncr}",
                        start: "${fechaIncr}",
                        end: "${new Date(
                            fechaIncr.getFullYear(),
                            fechaIncr.getMonth(),
                            fechaIncr.getDate(),
                            datosEvento.end.split(":")[0],
                            datosEvento.end.split(":")[1]
                        )}",
                        color: "${obEv.color}",
                        idTipo: ${obEv.idTipo},
                        tipo: "${obEv.tipo}",
                        modalidad1: "${obEv.modalidad1}",
                        modalidad2: "${obEv.modalidad2}",
                        notas: "${obEv.notas.replace(/\n/g," ")}",
                        direccion: "${obEv.direccion}",
                        listaInvitados: ${prepareListInvit.replace(/}{/g, "},{")},
                        iduPresentador: "${obEv.iduPresentador}",
                        correoPresentador: "${obEv.correoPresentador}",
                        nombrePresentador: "${obEv.nombrePresentador}",
                        seRepite: ${obEv.seRepite},
                        frecuencia: ${obEv.frecuencia},
                        seRepiteHasta: "${obEv.seRepiteHasta}",
                        idEventoLigado: "${idEventoLigado}",
                        fechaEvento: "${fechaEvento}",
                        horaInicioEvento: "${obEv.horaInicioEvento}",
                        horaFinEvento: "${obEv.horaFinEvento}",
                        fechaCreacion: "${new Date().toLocaleDateString('es-MX', { year: 'numeric', month: '2-digit', day: '2-digit' })}"
                ){
                    id titulo fecha start end color idTipo tipo modalidad1 modalidad2 notas direccion  idEventoLigado
                    listaInvitados{idu nombre correo segmento esExtr sendMail asistire referenciadoLMS} iduPresentador correoPresentador 
                    nombrePresentador seRepite frecuencia seRepiteHasta status fechaReagenda
                }
                }`;
                console.log('Request para nuevo evento: ');
                console.log(gql);
        return gql;
    };
    /**
     * Función para editar un evento
     */
    const editarEvento = () => {
        //console.log("Vamos a actualizar el evento ");
        //console.log(datosEvento);
        //To Do Ver9: Verify if disabling this State make no errors.
        //setCompleted(false);
        if(listaInvitados.length===0 && idTipoEvento!==5){
            let mensaje = "Agrega un invitado por lo menos a tu evento";
            alerts(mensaje);
            return;
        }
        let CheckExternos = false;
        console.log('Valor de listaInvitados: '+listaInvitados)
        listaInvitados.forEach((invt, i)=>{
            console.log('Valor unitario listaInvitados: '+invt.esExtr)
            if(invt.esExtr){
                console.log('Invitado Externo econtrado!');
                CheckExternos = true;
            }
        });
        console.log('idTipoEvento para comparación en actualización de evento: '+idTipoEvento)
        if(idTipoEvento===1 && CheckExternos===false){
            //To Do Ver8: Remove this console logs.
            console.log('No Hay Externos para actualizar evento, Detener Guardado.')
            let mensaje = "Debes agregar al menos un usuario externo para este tipo de evento."
            alerts(mensaje);
            return;
        }
        if(idTipoEvento===2 && CheckExternos===false){
            //To Do Ver8: Remove this console logs.
            console.log('No Hay Externos para actualizar evento, Detener Guardado.')
            let mensaje = "Debes agregar un usuario externo para este tipo de evento."
            alerts(mensaje);
            return;
        }
        if(idTipoEvento==='1' && CheckExternos===false){
            //To Do Ver8: Remove this console logs.
            console.log('No Hay Externos para actualizar evento, Detener Guardado.')
            let mensaje = "Debes agregar al menos un usuario externo para este tipo de evento."
            alerts(mensaje);
            return;
        }
        if(idTipoEvento==='2' && CheckExternos===false){
            //To Do Ver8: Remove this console logs.
            console.log('No Hay Externos para actualizar evento, Detener Guardado.')
            let mensaje = "Debes agregar un usuario externo para este tipo de evento."
            alerts(mensaje);
            return;
        }
        let start = new Date(
            eventDate.getFullYear(),
            eventDate.getMonth(),
            eventDate.getDate(),
            datosEvento.start.split(":")[0],
            datosEvento.start.split(":")[1]
        );
        let end = new Date(
            eventDate.getFullYear(),
            eventDate.getMonth(),
            eventDate.getDate(),
            datosEvento.end.split(":")[0],
            datosEvento.end.split(":")[1]
        );
        if (datosEvento.titulo.includes('"')) {
            datosEvento.fixtitulo = datosEvento.titulo.replaceAll('"','');
            //To Do Ver3: Remove this log and improve this anti spec chars function....
            console.log('Se encontraron caracteres especiales en el evento. Reemplazando...')
          } else {
            datosEvento.fixtitulo = datosEvento.titulo;
            console.log('Sin caracteres especiales.')
          }
        let obEv = {
            titulo: datosEvento.fixtitulo,
            fecha: eventDate,
            start: new Date(start.getTime()),
            end: new Date(end.getTime()),
            color: datosEvento.color,
            idTipo: idTipoEvento,
            tipo: tipoEvento,
            modalidad1: stateSwitch.checkedModalidad1 ? 'party': 'individual',
            modalidad2: stateSwitch.checkedModalidad2 ? 'virtual': 'presencial',
            notas: datosEvento.notas.replace(/\n/g," ").replace(/"/g, "").replace(/'/g, ""),
            direccion: datosEvento.direccion,
            listaInvitados: listaInvitados,
            iduPresentador: (user.rol[0].value === 0 ? user.correo: user.idu),
            correoPresentador: user.correoPresentador,
            nombrePresentador: user.nombre + (user.apellido && user.apellido.length>0? ' ' + user.apellido: ''),
            seRepite: seRepite,
            frecuencia: datosEvento.frecuencia,
            seRepiteHasta: datosEvento.seRepiteHasta,
            status: datosEvento.status,
            fechaReagenda: datosEvento.fechaReagenda
        };

        let prepareListInvit = "[";
        listaInvitados.forEach((invt, i)=>{
            prepareListInvit += '{idu: "'+ invt.idu + '", nombre: "'+ invt.nombre +
                (invt.apellido && invt.apellido!== null && invt.apellido.length>0 ? invt.apellido: '' ) +
                '", correo: "'+invt.correo+ '", segmento: "'+invt.segmento+'", esExtr: '+ invt.esExtr+', sendMail: ' +
                (invt.sendMail?invt.sendMail: false) + ', asistire: ' + (invt.asistire?invt.asistire:0) + ', referenciadoLMS: '+invt.referenciadoLMS+'}';
                console.log('----------El Valor para referenciado para UPDATE sería :'+invt.referenciadoLMS);
        });
        prepareListInvit += "]";
        //console.log(prepareListInvit);
        //console.log("Vamos a ver con que frecuencia se repite");
        //console.log(obEv.frecuencia);

        // eslint-disable-next-line
        let finAnio = new Date(obEv.fecha.getFullYear(), 12, 0);
        if(obEv.frecuencia==='3'){
            finAnio = new Date(obEv.fecha.getFullYear()+1, 12, 0);
        }

        let fechaIncr = new Date(
            eventDate.getFullYear(),
            eventDate.getMonth(),
            eventDate.getDate(),
            datosEvento.start.split(":")[0],
            datosEvento.start.split(":")[1]
        );
        function updateEvent(gql) {
            console.log(gql);
            
            AuthService.consult(gql.replace("\n", " "))
            .then(result => {
                setCompleted(true);
                //Vamos a validar si entro a reagendar mandar correo de reagendado
                if(entroReagendar){
                    let evento = result.data.data.updateEvent
                    enviarCorreoReagenda(evento)
                }
                setEventsDay([]);
                consultaEventos(user.rol[0].value === 0 ? user.correo: user.idu, today)
                setOpenModal(false);
                setNoEditarFecha(true);
            })
            .catch(error => {
                setCompleted(true);
                console.log("error updateEvent info: " + error);
                setEventsDay([]);
                consultaEventos(user.rol[0].value === 0 ? user.correo: user.idu, today);
                setOpenModal(false);
                setNoEditarFecha(true);
            });


        }
        do {
            //console.log(fechaIncr);
            let fechaEvento = fechaIncr.toLocaleDateString('es-MX', { year: 'numeric', month: '2-digit', day: '2-digit' });
            let query = `mutation{
              updateEvent(
                    id: "${datosEvento.id}"
                    titulo: "${obEv.titulo}",
                    color: "${obEv.color}",
                    idTipo: ${obEv.idTipo},
                    tipo: "${obEv.tipo}",
                    modalidad1: "${obEv.modalidad1}",
                    modalidad2: "${obEv.modalidad2}",
                    notas: "${obEv.notas}",
                    direccion: "${obEv.direccion}",
                    listaInvitados: ${prepareListInvit.replace(/}{/g, "},{")},
                    seRepite: ${obEv.seRepite},
                    frecuencia: ${obEv.frecuencia}, 
                    seRepiteHasta: "${obEv.seRepiteHasta}", 
                    fecha: "${fechaIncr}",
                    start: "${fechaIncr}",
                    end: "${new Date(
                        fechaIncr.getFullYear(),
                        fechaIncr.getMonth(),
                        fechaIncr.getDate(),
                        datosEvento.end.split(":")[0],
                        datosEvento.end.split(":")[1]
                    )}",
                    fechaEvento: "${fechaEvento}",
                    horaInicioEvento: "${datosEvento.start}",
                    horaFinEvento: "${datosEvento.end}" `
                    +
                    ((entroReagendar) ?
                        `
                        status: 2 
                        fechaReagenda: "${fechaIncr}" ,
                        fechaReagendaFormato: "${fechaEvento}", 
                        fechaFinReagenda: "${new Date(
                            fechaIncr.getFullYear(),
                            fechaIncr.getMonth(),
                            fechaIncr.getDate(),
                            datosEvento.end.split(":")[0],
                            datosEvento.end.split(":")[1]
                        )}", 
                        fechaFinReagendaFormato: "${
                            fechaIncr.getDate() + '/' + fechaIncr.getMonth() + '/' + fechaIncr.getFullYear()
                        }", 
                        horaInicioReagenda: "${datosEvento.start}", 
                        horaFinReagenda: "${datosEvento.end}" 
                        ` 
                    :
                        ` status: ${datosEvento.status}
                        fechaReagenda: "" ,
                        fechaReagendaFormato: "", 
                        fechaFinReagenda: "", 
                        fechaFinReagendaFormato: "", 
                        horaInicioReagenda: "", 
                        horaFinReagenda: "" `
                    )
                    +
            `){
                id titulo fecha start end color idTipo tipo modalidad1 modalidad2 notas direccion idEventoLigado 
                listaInvitados{idu nombre correo segmento esExtr sendMail asistire referenciadoLMS} iduPresentador correoPresentador
                nombrePresentador seRepite frecuencia seRepiteHasta status fechaReagenda
              }
            }`;
            //console.log(query) ;
            updateEvent(query)

            switch(obEv.frecuencia){
                case "1":  // Sumar 7 dias
                    fechaIncr.setDate(fechaIncr.getDate()+7);
                    break;
                case "2":  // Sumar meses
                    fechaIncr.setMonth(fechaIncr.getMonth()+1);
                    break;
                case "3":  // Sumar 1 año
                    fechaIncr.setFullYear(fechaIncr.getFullYear()+1);
                    break;
                default:
                    finAnio = fechaIncr;
                    break;
            }
        } while( false ); //fechaIncr<finAnio);  //Solo debería editar este evento
        // obEv.fecha < finAnio
        //console.log(finAnio);
    };
    /**
     * Función para consultar eventos de acuerdo a la fecha seleccionada en el calendario
     * @param {*} date Fecha seleccionada
     */
    const navegarCalendario = (date) => {
        // console.log("checale..." );
        //console.log(date);
        setDefaultDate(date)
        consultaEventos(user.rol[0].value === 0 ? user.correo: user.idu, date);
    };
    const customDayPropGetter = date => {
        if (defaultDate && date.getDate() === defaultDate.getDate() && date.getMonth() === defaultDate.getMonth())
            return {
                className: 'special-day',
                style: {
                    border: 'solid 3px ' +
                    ( (date.getDate() === defaultDate.getDate() && date.getMonth() === defaultDate.getMonth() ) ? '#00AC46' : '#afa'),
                },
            };
        else return {
            className: 'day-' + date.getDate() + '-' + date.getMonth() + '-' + defaultDate.getFullYear(),
                style: {
                    // border: 'solid 3px #e53e00',
                    // background: '#ffc7b2'
                },
        }
    };

    const customSlotPropGetter = date => {
        if (defaultDate && date.getDate() === defaultDate.getDate() && date.getMonth() === defaultDate.getMonth())
            return {
                className: 'special-day',
                style: {
                    border: 'solid 3px ' +
                    ( (date.getDate() === defaultDate.getDate() && date.getMonth() === defaultDate.getMonth() ) ? '#00AC46' : '#afa'),
                },
            };
        else return {}
    };
    /**
     * Función para enviar invitación por correo solo a ausuarios externos
     * @param {*} evento Datos del evento
     */
    const enviarInvitacion = (evento) => {
        /* REVISAR LISTA DE INVITADOS PARA DETECTAR QUE SOLO SEAN EXTERNOS */
        evento.listaInvitados.forEach( usrExt => {
            let mandarCorreo = false;
            if(usrExt.esExtr && (parseInt(evento.idTipoEvento)===1 || parseInt(evento.idTipoEvento)===2 || parseInt(evento.idTipoEvento)===4 )){
                mandarCorreo = true;
            }
            if(mandarCorreo){
                AuthService.sendMailInvtExtrEvnt(user.correo, usrExt.correo, evento.tipo, usrExt.nombre, evento.start)
                    .then(resp => {
                        console.log(resp);
                        setOpenModal(false)
                    })
                    .catch(error=>{console.log(error)});
            }
        })
    };  
    /**
     * Función para eliminar un evento
     * @param {*} dataEvent Datos del evento
     */
    const eliminarEvento = (dataEvent) => {
        //AuthService.consult(`mutation{removeEvent(id: "${id}"){ id }}`)
        console.log(dataEvent)
        if(dataEvent.seRepite){
            //Tenemos que verificar si este evento se repite
            setQuestion('¿Quieres cancelar solo este evento o todos los eventos creados?');
            handleOpenQuestion();
        } else {
            setQuestion("¿Está seguro de cancelar este evento?");
            handleOpenQuestion();
            //handleCancelEvent();
        }
    };

    const handleOpenQuestion = () => {
        setOpenQuestion(true)
    }
    const handleCloseQuestion = () => {
        setOpenQuestion(false)
    }
    /**
     * Función para eliminar todos los eventos ligados al ID padre
     */
    const handleCancelEventsAll = () => {

        console.log("Vamos a eliminar todos los eventos ligados al ID: " + datosEvento.id +" si existe el idPadre al id: " + datosEvento.idEventoLigado)
        console.log(datosEvento)
        let idEliminar = ( datosEvento.idEventoLigado.length>0 ? datosEvento.idEventoLigado: datosEvento.id )
        AuthService.consult(`mutation{
          updateStatusEventoAllId( id:"${idEliminar}" status: 0 ){
            id
          }
        }`)
            .then(result => {
                console.log("Se ha cancelado todos los evento: " + result.data.data.updateStatusEventoAllId);
                alerts("Los eventos asociados a este evento han sido cancelados.", false);
                //AuthService.cancelAppointment(datosEvento.idAppointment);
                setOpenModal(false);
                setOpenQuestion(false)
                setNoEditarFecha(true);
                consultaEventos(user.rol[0].value === 0 ? user.correo: user.idu, today);
            })
            .catch(error => {
                console.log("error updateStatusEventoAllId info: " + error);
            });
        //Vamos a cancelar todos los eventos
    }
    /**
     * Función para cancelar un evento
     */
    const handleCancelEvent = () => {

        console.log("Vamos a eliminar solo este evento")
        console.log(datosEvento)

        AuthService.consult(`mutation{
          updateStatusEvento( id:"${datosEvento.id}" status: 0 ){
            id
          }
        }`)
            .then(result => {
                //console.log("Se ha cancelado el evento: " + result.data.data.removeEvent.id);
                alerts("El evento ha sido cancelado.", false);
                //AuthService.cancelAppointment(datosEvento.idAppointment);
                setOpenModal(false);
                setOpenQuestion(false)
                setNoEditarFecha(true);
                consultaEventos(user.rol[0].value === 0 ? user.correo: user.idu, today);
            })
            .catch(error => {
                console.log("error updateStatusEvento info: " + error);
            });

        // Vamos a cancelar solo este evento
    };

    const handleChangeSelectAll = (ev) => {
        let newArrInvt = []
        let rsplListaInvt = JSON.parse(JSON.stringify(listaInvitados))
        //console.log("Selecionar todos los usuarios para invitar: " + ev.target.checked)
        setCheckSelectAll(ev.target.checked);
        let quitar = false
        //To Do VerZ2: Validate if this changes al keept on branches merge.
        if(ev.target.checked){
            // agregar todos a invitados
            let newInvt = []
            personas.forEach(el=>{
                let obNew = esUsuarioInterno ?
                    {
                        idu: el.idu.length>0 ? el.idu: el.correo,
                        nombre: el.nombre + ' ' + (el.apellido && el.apellido!==null && el.apellido.length>0 ? el.apellido: ''),
                        correo: el.correo, segmento: [], esExtr: false, referenciadoLMS: false
                    } :
                    {idu:el.idu, nombre: el.nombre, correo: el.correo, segmento: el.perfil, esExtr: true, referenciadoLMS: el.referenciadoLMS};
                //Vamos a revisar si ya esta en la lista de invitados ya no agregarlo
                let existe = listaInvitados.filter(invt => invt.idu === obNew.idu).length>0
                if(!existe){
                    newInvt.push(obNew)
                }
            });
            //Sacar el idu del usuario conectado para que no se auto invite
            newArrInvt = (newInvt.filter(invt=>invt.idu!==user.idu));
        } else {
            // quitar todos estas personas de invitados
            console.log(personas)
            quitar = true;
            //To Do VerZ2: Check if new params for Invitados Externos must be added here for Recomienda y Gana.
            personas.forEach(el=>{
                let obNew = esUsuarioInterno ?
                    {
                        idu: el.idu.length>0 ? el.idu: el.correo,
                        nombre: el.nombre + ' ' + (el.apellido && el.apellido!==null && el.apellido.length>0 ? el.apellido: ''),
                        correo: el.correo, segmento: [], esExtr: false
                    } :
                    {idu:el.idu, nombre: el.nombre, correo: el.correo, segmento: el.perfil, esExtr: true, referenciadoLMS: el.referenciadoLMS, ETRealizada: el.ETRealizada};
                rsplListaInvt = rsplListaInvt.filter(invt => invt.idu !== obNew.idu)
            })
            //newArrInvt = ([]);
        }
        if(quitar){
            setListaInvitados(rsplListaInvt);
        } else {
            setListaInvitados([].concat(listaInvitados, newArrInvt));
        }
    };

    const reagendar = (datos) => {
        setNoEditarFecha(!noEditarFecha);
        setEntroReagendar(true);
    };
    /**
     * Función para mandar correo a lista de asistencia de invitados
     */
    const enviarCorreo = () => {
        //console.log(datosEvento.fechaEvento)
        //console.log(datosEvento.horaInicioEvento)
        //Enviar correo de asistencia y pasar evento a realizado
        console.log('---------------------->Función del pase de lista corriendo. Valor del ID de Usuario: '+user.id_user);
        console.log('---------------------->Función del pase de lista corriendo. Valor del ID de Usuario: '+user.id_user);
        let sendMailInvt = JSON.parse(window.localStorage.getItem("enviarAsistencia"));
        let usrsEnvrCrro = (sendMailInvt && sendMailInvt.length>0) ? sendMailInvt.filter((el,i)=>el.checked): [];
        //console.log(sendMailInvt);
        if(usrsEnvrCrro.length>0){
            //console.log(usrsEnvrCrro)
            //console.log(datosEvento.start)
            usrsEnvrCrro.forEach((invt, i)=>{
                let mandarCorreo = false;
                if(invt.esExtr && (parseInt(idTipoEvento)===1 || parseInt(idTipoEvento)===2 || parseInt(idTipoEvento)===4 )){
                    mandarCorreo = true;
                }
                //console.log("datosEvento para lista de asistencia", datosEvento)
                if(mandarCorreo){
                    let fechaEvento = datosEvento.fechaEvento
                    let horaEvento = datosEvento.horaInicioEvento
                    if(datosEvento.status===2){
                        fechaEvento = datosEvento.fechaReagendaFormato
                        horaEvento = datosEvento.horaInicioReagenda
                    }
                    AuthService.sendMailInvtExtrEvnt(
                        (user.rol[0].value === 0 ? user.correo: user.idu), user.correo, invt.correo,
                        datosEvento.tipo, invt.nombre, fechaEvento, horaEvento, user.id_user, invt.referenciadoLMS)
                    .then(resp => {
                        //console.log(resp);

                        //Recorrer los invitados del evento y modificar campo de sendMail a true
                        listaInvitados.forEach((invt, key)=>{
                            Object.assign(invt, {"sendMail": false})
                            let arrUsrInvtExtr = usrsEnvrCrro.filter(extr=>extr.idu===invt.idu);
                            if(arrUsrInvtExtr.length>0){
                                //Vamos a cambiar a true sendMail
                                Object.assign(invt, {"sendMail": true})
                            }
                        });
                        //To Do VerZ2: Actualizar registros y enviar notifiación NetSuite para REcomienda y Gana.


                        //To Ver Z2: Delete this----
                        function prettyJSONv(obj) {
                            let stringed = JSON.stringify(obj, null, 2);
                            return stringed
                        }
                        



                        listaInvitados.forEach((invt, key)=>{
                            //Object.assign(invt, {"sendMail": false})
                            let arrUsrInvtExtr = usrsEnvrCrro.filter(extr=>extr.idu===invt.idu);
                            if(arrUsrInvtExtr.length>0){
                                //To Do VerZ2:------------On Progress.
                                //Registrar el NetSuiteID y enviar Request a la API de Agenda.
                                console.log('el número de invitados externos para procesar es: '+arrUsrInvtExtr.length);
                                let PrettyThis = prettyJSONv(arrUsrInvtExtr);//To Ver Z2: Delete this----
                                console.log('-----Registro de asistentes, se está trabajando con el reg: '+PrettyThis);//To Ver Z2: Delete this----
                                //To Do VerZ2: Check if this function breaks in older events.
                                console.log('El Valor de Referenciado es: '+arrUsrInvtExtr[0].referenciadoLMS);
                                if(arrUsrInvtExtr[0].referenciadoLMS){
                                    console.log('El Contacto Es Referenciado, Mandar API Request.');
                                    AuthService.ReportExpNetsuite(arrUsrInvtExtr[0].idu)
                                            .then(resp => {
                                                console.log(resp);
                                            })
                                            .catch(error=>{console.log(error)});
                                } else {
                                    console.log('El Contacto NO es referenciado.');
                                }
                            }
                        });
                        //console.log(listaInvitados);

                        let prepareListInvit = "[";
                        listaInvitados.forEach((invt, i)=>{
                            prepareListInvit += '{idu: "'+ invt.idu + '", nombre: "'+ invt.nombre + '", correo: "' +
                                invt.correo+'", segmento: "'+invt.segmento+'", esExtr: '+invt.esExtr+', sendMail: '+
                                invt.sendMail+', asistire: '+invt.asistire + ', referenciadoLMS: '+invt.referenciadoLMS+'}';
                        });
                        prepareListInvit += "]";

                        //Vamos a guardar las lista de invitados
                        let gqlUpd = `mutation{
                          updateInvitadosEventMail(
                            id: "${datosEvento.id}"
                            listaInvitados: ${prepareListInvit.replace(/}{/g, "},{")},
                          ){
                            id
                            listaInvitados {
                              idu
                              nombre
                              correo
                              segmento
                              esExtr
                              sendMail
                              asistire
                            }
                          }
                        }`;
                        //console.log(gqlUpd);
                        setTimeout(()=>{
                            AuthService.consult(gqlUpd)
                                .then(resp=> {
                                    //console.log(resp.data.data.updateInvitadosEventMail)
                                })
                                .catch(error=>console.log(error));
                        },1000)

                        // Al final de hacer el proceso de enviar el correo eliminar la cookie
                        setMostrarAsistencia(false);
                        setOpenQuestEdit(false);
                        window.localStorage.removeItem("enviarAsistencia");
                        if(i===usrsEnvrCrro.length-1){
                            alerts("Se ha enviado correo a los invitados que asistieron al evento.", false)
                        }
                    })
                    .catch(error=>{console.log(error)});
                }
            });

        } else {
            alerts("Debes seleccionar por lo menos a un usuario para enviar el correo.", true)
        }

    };
    /**
     * Función para enviar correo al crear evento y solo para usuarios externos
     * @param {*} sendMailInvt Lista de invitados a evento
     * @param {*} nameEvent Nombre del evento
     * @param {*} idEvento ID del evento
     */
    const enviarCorreoInvitacion = (sendMailInvt, nameEvent, idEvento) => {
        //console.log(sendMailInvt);
        //console.log(typeof(idTipoEvento) + " idTipoEvento: " + idTipoEvento);
        sendMailInvt.forEach((invt, i)=>{
            let mandarCorreo = false;
            //Validar usuarios internos se manda de todos los eventos
            // Si es externo solo idTipoEvento 1,2 y 4
            if(invt.esExtr && (parseInt(idTipoEvento)===1 || parseInt(idTipoEvento)===2 || parseInt(idTipoEvento)===4 )){
                mandarCorreo = true;
            }
            /*
            if( !invt.esExtr ){
                mandarCorreo = true;
            }
            */
            if(mandarCorreo) {
                AuthService.sendMailInvitaEvento(
                    idEvento, (user.rol[0].value === 0 ? user.correo : user.idu),
                    (user.nombre + (user.apellido && user.apellido!==null && user.apellido.length>0? ' ' + user.apellido: '')),
                    user.correo, invt.correo, nameEvent, invt.nombre, eventDate.toLocaleDateString(), datosEvento.start,
                    datosEvento.direccion
                )
                    .then(resp => {
                        setTimeout(() => {
                            console.log("Se envió un correo de invitación " + resp);
                        }, 1000)
                        if (i === sendMailInvt.length - 1) {
                            alerts("Se ha enviado correo de invitación a los invitados.", false)
                        }
                    })
                    .catch(error => {
                        console.log(error)
                    });
            }
        });
    };
    /**
     * Función para enviar correo cuando se reagenda un evento
     * @param {*} event Datos del evento
     */
    const enviarCorreoReagenda = (event) => {
        console.log("datosEvento para reagendar: ", event);
        //console.log(eventDate);

        event.listaInvitados.forEach((invt, i)=>{
            let mandarCorreo = false;
            //Validar usuarios internos se manda de todos los eventos
            // Si es externo solo idTipoEvento 1,2 y 4
            if(invt.esExtr && (parseInt(idTipoEvento)===1 || parseInt(idTipoEvento)===2 || parseInt(idTipoEvento)===4 )){
                mandarCorreo = true;
            }
            /*
            if( !invt.esExtr ){
                mandarCorreo = true;
            }
            */
            if(mandarCorreo) {
                AuthService.sendMailReagendaEvento(
                    event.id,(user.rol[0].value === 0 ? user.correo : user.idu), (user.nombre +
                        (user.apellido && user.apellido!==null && user.apellido.length>0 ? ' ' + user.apellido : '')),
                    user.correo, invt.correo, event.tipo, invt.nombre, eventDate.toLocaleDateString(), datosEvento.start,
                    datosEvento.direccion)
                    .then(resp => {
                        setTimeout(() => {
                            console.log("Se envió un correo de reagendar " + resp);
                        }, 1000)
                        if (i === event.listaInvitados.length - 1) {
                            alerts("Se ha enviado correo a los invitados del evento reagendado.", false)
                        }
                    })
                    .catch(error => {
                        console.log(error)
                    });
            }
        });

    };

    const noSeRealizo = () => {
        setOpenQuestNoSeRlzo(true)
    };

    const aceptoNoSeRealizo = () => {
        console.log("Evento no realizado... ");
        console.log(datosEvento)
        AuthService.consult(`mutation{ updateStatusEvento(
                                id:"${datosEvento.id}"
                            status: 3
                        ){
                                id
                                status
        }}`).then(resp=>{
            console.log(resp.data.data.updateStatusEvento);
            alerts("Se ha actualizado el estatus del evento a 'No se realizó'", false);
            setOpenQuestEdit(false);
            setOpenQuestNoSeRlzo(false);
        })
            .catch(err=>console.log(err))
    };
    /**
     * Función para validar hasta cuando se puede pasar asistencia
     * @returns True or False dependiendo de los Parametros de Asistencia
     */

    const validateMondayCheckIcon = (EvntDate) => {
        var fechaActual = new Date();
        var diaSemana = fechaActual.getDay();
        var diasParaRetroceder = diaSemana === 1 ? 0 : diaSemana + (7 - 1); // Retroceder al último lunes
        diasParaRetroceder = diasParaRetroceder - 7;
        var ultimoLunes = new Date(fechaActual);
        ultimoLunes.setDate(fechaActual.getDate() - diasParaRetroceder);
        ultimoLunes.setHours(23, 59, 59, 999);
        //console.log('--------------->El último Lunes fue:'+ultimoLunes);
        let EvntDateCompare = new Date(EvntDate);
        //console.log('---------->La Fecha de EvntDateCompare Es:'+EvntDateCompare)

        //28 Enero 2025
        let ProvisionalWeek = new Date(1738044000000);
        //04 de Febrero 2025
        //let ProvisionalTuesday = new Date(1738648800000);
        
        if(EvntDateCompare.getTime()>ProvisionalWeek.getTime()){
            return true;
        } else if(EvntDateCompare.getTime()<ultimoLunes.getTime()){
            return false;
        } else {
                //return (noDiasEvntAHoy<rangoDias)
                return true;
        }   
    }

    const validateMonday = () => {
        //let resp = true
        // 0 1 2 3 4 5 6 7
        let rangoDias = 7*24*60*60*1000
        let dateEvento = ( datosEvento.status === 2 ? new Date(datosEvento.fechaReagenda): new Date(datosEvento.fecha) )
        dateEvento = new Date(dateEvento.getFullYear(), dateEvento.getMonth(), dateEvento.getDate())
        let noDiaSmnaHoy = new Date().getDay();
        noDiaSmnaHoy = noDiaSmnaHoy === 0 ? 7: noDiaSmnaHoy;
        let diasPrxmLnes = (8-noDiaSmnaHoy)===7?0:(8-noDiaSmnaHoy)
        let dateHoy = new Date().getTime()
        let WichToday = new Date();
        let datePrxmLnes = new Date(dateHoy + (diasPrxmLnes*24*60*60*1000) )
        datePrxmLnes = new Date(datePrxmLnes.getFullYear(), datePrxmLnes.getMonth(), datePrxmLnes.getDate())
        //Vamos a sacar la diferencia del proximo lunes al día de hoy contrra la fecha del evento si es mayor a un lapso de 8 dias
        let noDiasEvntAHoy = datePrxmLnes.getTime() - dateEvento.getTime()

        //To Do VerZ3: Changes for provisonal monday validation starts here.
        console.log('--------> Fecha Reducida del Evento Recibida: '+dateEvento)
        console.log('--------> Fecha Unix del Evento Recibida: '+dateEvento.getTime())
        console.log('----------> Valor recibido de noDiasEvntAHoy: '+noDiasEvntAHoy)
        //To Do VerZ3: Delete this provisional functions for monday validation-------------
        //Se define provisionalmente la fecha ISO:20230314T060000Z en Unix para el ajuste de esta semana.
        //let ProvisionalWeek = new Date(1678773600000);
        //25 de Abril: 1682402400
        //24 de Octubre: 1698127200

        //let ProvisionalWeek = new Date(1700546400000);

        //28 Enero 2025
        let ProvisionalWeek = new Date(1738044000000);
        //04 de Febrero 2025
        //let ProvisionalTuesday = new Date(1738648800000);



        //04 de Febrero 2025
        let ProvisionalTuesday = new Date(1738648800000);


        //Martes 21 de Marzo
        //let ProvisionalTuesday = new Date(1679378400000)
        //Martes 02 de Mayo: 1683007200
        //Martes 31 de Octubre=1698732000
        //let ProvisionalTuesday = new Date(1698732000000);
        //Martes 21 de Noviembre=1698732000
        //let ProvisionalTuesday = new Date(1701151200000);
        //Martes 06 de Febrero 2024=1698732000
        //let ProvisionalTuesday = new Date(1710828042000);
        
        //Martes 21 de Marzo
        //let ProvisionalTuesday = new Date(1679378400000)
        //Martes 02 de Mayo: 1683007200
        //Martes 31 de Octubre=1698732000
        //let ProvisionalTuesday = new Date(1698732000000);
        //Martes 21 de Noviembre=1698732000
        //let ProvisionalTuesday = new Date(1701151200000);
        //Martes 06 de Febrero 2024=1698732000
        //let ProvisionalTuesday = new Date(1710828042000);


        let LastWeek2023Start = new Date(1703483940000);
        let LastWeek2023End = new Date(1703915940000);

        if(parametroAsistencia){
          return parametroAsistencia
        } else {
            if(dateEvento.getTime()<=ProvisionalTuesday.getTime()&&dateEvento.getTime()>=ProvisionalWeek.getTime()){
                //return (noDiasEvntAHoy<rangoDias)
                console.log('Rango días: '+rangoDias);
                //return false;
                return true;
            } else if(dateEvento.getTime()>LastWeek2023Start.getTime()&&dateEvento.getTime()<LastWeek2023End.getTime()){
                if(WichToday.getTime()>LastWeek2023End.getTime()){
                    return false;
                } else {
                    return true;
                }
            } else {
                //return true;
                return (noDiasEvntAHoy<rangoDias)
            }
            //return (noDiasEvntAHoy<rangoDias)
        }
    }

    const validateDateMonday = () => {

        // 0 1 2 3 4 5 6 7
        //let rangoDias = 7*24*60*60*1000
        //console.log(datosEvento)
        let dateEvento = new Date(datosEvento.status===2?datosEvento.fechaReagenda:datosEvento.fecha)
        let noDiaSmnaEvnt = new Date(datosEvento.status===2?datosEvento.fechaReagenda:datosEvento.fecha).getDay();
        //console.log("fecha evento: " + dateEvento)
        //console.log("no dia semana evento: " + noDiaSmnaEvnt)
        let diasPrxmLnesValido = 0;
        switch(noDiaSmnaEvnt){
            case 0:
                diasPrxmLnesValido = 1;
                break;
            case 1:
                diasPrxmLnesValido = 0;
                break;
            case 2:
                diasPrxmLnesValido = 6;
                break;
            case 3:
                diasPrxmLnesValido = 5;
                break;
            case 4:
                diasPrxmLnesValido = 4;
                break;
            case 5:
                diasPrxmLnesValido = 3;
                break;
            case 6:
                diasPrxmLnesValido = 2;
                break;
            default:
                diasPrxmLnesValido = 0;
                break;
        }
/*
        let noDiaSmnaHoy = new Date().getDay();
        noDiaSmnaHoy = noDiaSmnaHoy === 0 ? 7: noDiaSmnaHoy;
        let diasPrxmLnes = (8-noDiaSmnaHoy)===7?0:(8-noDiaSmnaHoy)
        let diasPrxmLnesValido = (8-noDiaSmnaEvnt)===7?0:(8-noDiaSmnaEvnt)
        let dateHoy = new Date().getTime()
*/

        //console.log("diasPrxmLnesValido: " + diasPrxmLnesValido)
        //let datePrxmLnes = new Date(dateHoy + (diasPrxmLnesValido*24*60*60*1000) )

                //To Do VerZ3: Delete previous comented consle logs from LuisR

        //To Do VerZ3: Provisional functions for monday validation---------------
        //Se define provisionalmente la fecha ISO:20230314T060000Z en Unix para el ajuste de esta semana.
        let ProvdateEvento = ( datosEvento.status === 2 ? new Date(datosEvento.fechaReagenda): new Date(datosEvento.fecha) )
        ProvdateEvento = new Date(dateEvento.getFullYear(), dateEvento.getMonth(), dateEvento.getDate())
        console.log('Value for ProvdateEvento:'+ProvdateEvento);
        console.log('Value Epoch for ProvdateEvento:'+ProvdateEvento.getTime());
        //28 Enero 2025
        let ProvisionalWeek = new Date(1738044000000);
        //04 de Febrero 2025
        let ProvisionalTuesday = new Date(1738648800000);
        console.log('Value Epoch for ProvisionalWeek:'+ProvisionalWeek.getTime());

        let LastWeek2023Start = new Date(1703483940000);
        let LastWeek2023End = new Date(1703915940000);
        console.log('------------>Fecha Inicio última semana 2023:'+LastWeek2023Start);
        console.log('------------>Fecha Final última semana 2023:'+LastWeek2023End);

        if(ProvdateEvento.getTime()===ProvisionalTuesday.getTime()){
            diasPrxmLnesValido = 0;
        } else if (ProvdateEvento.getTime()>=ProvisionalWeek.getTime()&&ProvdateEvento.getTime()<=ProvisionalTuesday.getTime()){
            diasPrxmLnesValido = diasPrxmLnesValido+1;
            console.log('----------->Provisional Monday Detected--------------')
        } else {
            console.log('-----------Regular Monday--------------')
        }
        console.log('--------------->EVENT DAY REGISTER:'+ProvdateEvento.getTime());
        //To Do VerZ3: End of Provisional functions-------------------------------


        let datePrxmLnesValido = new Date(dateEvento.getTime() + (diasPrxmLnesValido*24*60*60*1000) )

        //To Do VerZ3: Monday Validation for special cases on prog. - Delte next console log.
        console.log('---------> Valor para Lunes Válido recibido: '+datePrxmLnesValido);

        //Vamos a sacar la diferencia del proximo lunes al día de hoy contrra la fecha del evento si es mayor a un lapso de 8 dias
        //let noDiasEvntAHoy = diasPrxmLnesValido //datePrxmLnes.getTime() - dateEvento.getTime()

        return (datosEvento.idTipo===1 || datosEvento.idTipo===2 || datosEvento.idTipo===4) ?
            ( ('Fecha límite del pase de lista: ' ) + (datePrxmLnesValido.toLocaleDateString('es-MX',
                { year: 'numeric', month: '2-digit', day: '2-digit' }) )): '';
    }

    return(
        <div className="container-schedule schedule col l12 m12 s12">
            <ToastsContainer store={ToastsStore} position={ToastsContainerPosition.TOP_RIGHT} style={{height:"125px"}}/>
            {
            !completed ?
                <Lottie
                    loop
                    animationData={loading}
                    play
                    style={{ width: 450, height: 450, display: "block", margin: "auto" }}
                />:
            <Grid container spacing={3}>
                {/* * * * D E T A L L E  D E L  D I A * * * * */}
                <Grid item xs={12} md={4} lg={3} className="nextView">
                    <Paper className={fixedHeightPaper + "MyDayDetail"}>

                        {/*
                        <Button className="MuiBadge-colorSecondary" onClick={handleClickOpenModal}>+ Agregar evento</Button>
                        <detalle del día />
                        */}
                        <div>
                            <h5 className = "todayCalendar">{fechaTitle}</h5>
                            <span className = "todayCalendar todayCalendarSub">Detalles del día</span>
                            { eventsDay && eventsDay.length>0 &&
                            eventsDay.map((event,i) =>
                                <div className="NextEventElement" key={i}>
                                    <Divider style={{marginBottom: "10px"}}/>
                                    {
                                        event &&
                                        <div className="row" style={{marginBottom: '10px'}}>
                                            {
                                                event.start.length <= 5 ?
                                                    <div className="col l12" style={{padding: "0"}}>
                                                        <label className="col l6" style={{fontSize: "13px", textTransform:"lowercase"}}>
                                                            {
                                                                event.start + ' ' + (event.start.split(":")[0]>=13?'pm':'am')
                                                            }
                                                        </label>
                                                        <span className="col l6" style={event.status===0 ? {color: "red"}:{}}>
                                                    {event.status===0 ? 'CANCELADO': ''}
                                                </span>
                                                    </div>
                                                    :
                                                    <div className="col l12" style={{padding: "0"}}>
                                                        <label className="col l6" style={{fontSize: "12px"}}>{
                                                            new Date(event.start).getHours()+':'+
                                                            (new Date(event.start).getMinutes()<10 ?
                                                                '0'+new Date(event.start).getMinutes().toString():
                                                                new Date(event.start).getMinutes()) +
                                                            ' ' +
                                                            new Date(event.start).toLocaleTimeString('es-US').split(' ')[1]
                                                        }</label>
                                                        <span className="col l6" style={event.status===0 ? {color: "red"}:{}}>
                                                        {event.status===0 ? 'CANCELADO': ''}
                                                    </span>
                                                    </div>
                                            }

                                            <br/>
                                            {
                                            (event.status!==3) && validateMondayCheckIcon(event.fixStart) && event.idTipo===1 && event.listaInvitados.filter(invt => invt.sendMail).length === 0 &&
                                            <>
                                                <div className={"col l2 divcheck_" + event.tipo.toLowerCase().replace(/ /g,"_")}>
                                                    <img src="/assets/img/icons/Pase-de-lista_pendiente.png" className="iconCheckCalendar" alt="Pase de lista"/>
                                                </div>
                                            </>
                                            }
                                            {
                                            (event.status!==3) && !validateMondayCheckIcon(event.fixStart) && event.idTipo===1 && event.listaInvitados.filter(invt => invt.sendMail).length === 0 &&
                                            <>
                                                <div className={"col l2 divcheck_" + event.tipo.toLowerCase().replace(/ /g,"_")}>
                                                    <img src="/assets/img/icons/Pase-de-lista_vencido.png" className="iconCheckCalendar" alt="Pase de lista"/>
                                                </div>
                                            </>
                                            }
                                            {
                                            (event.status!==3) && event.idTipo===1 && event.listaInvitados.filter(invt => invt.sendMail).length > 0 &&
                                            <>
                                                <div className={"col l2 divcheck_" + event.tipo.toLowerCase().replace(/ /g,"_")}>
                                                    <img src="/assets/img/icons/Pase-de-lista_realizado.png" className="iconCheckCalendar" alt="Pase de lista"/>
                                                </div>
                                            </>
                                            }
                                            {
                                            (event.idTipo!==1) &&
                                            <>
                                                <div className={"col l2 divStar_" + event.tipo.toLowerCase().replace(/ /g,"_")}>
                                                        <StarIcon className={"star_" + event.tipo.toLowerCase().replace(/ /g,"_")}/>
                                                </div>
                                            </>
                                            }
                                            <div className="col l9" style={{paddingRight: "0"}}>
                                                <span style={{fontSize: "13px", fontWeight: "600"}}>
                                                    {event.titulo.length>0 ? event.titulo: event.tipo}
                                                </span>
                                                <br/>
                                                {
                                                    (user.correo === event.correoPresentador) &&
                                                    <span>
                                                    {
                                                        event.listaInvitados.map((el,i)=>
                                                            (i<6) &&
                                                            <label key={i} style={{color: "#9A9A9A", fontSize: "11px", fontWeight: "500"}}>
                                                                {
                                                                    el.nombre + (el.apellido && el.apellido.length>0 ? el.apellido: '') +
                                                                    ((i===5 && event.listaInvitados.length>5) ?
                                                                        ', ...' :
                                                                        (i < event.listaInvitados.length-1) ? ', ':'')
                                                                }
                                                            </label>

                                                        )
                                                    }
                                                    </span>
                                                }
                                                <br/>
                                                {
                                                    event.seguimientoUnico.map((el,i)=>

                                                        <span key={i} style={{color: "#9A9A9A", fontSize: "11px", fontWeight: "500"}}>
                                                            - {el} <br/>
                                                        </span>

                                                    )
                                                }

                                            </div>
                                            <div className="col l1 nopadding">
                                                <Tooltip title="Editar" placement="top">
                                                    <IconButton onClick={()=>handleClickOpenEditaModal(event)}  className="nopadding">
                                                        <MoreHorizIcon />
                                                    </IconButton>
                                                </Tooltip>
                                            </div>
                                        </div>
                                    }

                                </div>
                            )
                            }

                        </div>
                    </Paper>
                </Grid>
                {/* Agenda */}
                <Grid item xs={12} md={8} lg={9} className="calendarView">
                    <Paper className="calendarCnt">
                        { /* <Chart */}

                        <div className="container_calendar">
                            {/* * * C A L E N D A R I O * * */}
                            <DragAndDropCalendar messages={messages}
                                selectable = {true}
                                //rtl={rtl}
                                                 formats={formats}
                                                 culture={culture}
                                                 localizer={localizer}
                                // start time 8:00am
                                                 min={
                                                     new Date(
                                                         today.getFullYear(),
                                                         today.getMonth(),
                                                         today.getDate(),
                                                         8
                                                     )
                                                 }
                                // end time 5:00pm
                                                 max={
                                                     new Date(
                                                         today.getFullYear(),
                                                         today.getMonth(),
                                                         today.getDate(),
                                                         17
                                                     )
                                                 }
                                                 dayPropGetter={customDayPropGetter}
                                                 slotPropGetter={customSlotPropGetter}
                                                 events={eventsMonth}
                                                 onEventDrop={moveEvent}
                                                 resizable = {true}
                                                 onEventResize={resizeEvent}
                                                 onSelectSlot={handleClickSelectDay}
                                                 defaultView={Views.MONTH}
                                                 views={['month', 'week', 'agenda']}
                                                 onNavigate={(date)=>navegarCalendario(date)}
                                                 onSelectEvent={selectEvent}
                                                 onDoubleClickEvent={ doubleClickEvent}
                                                 date = {defaultDate}
                                                 components={{
                                                     event: function Event({ event }) {
                                                         return (
                                                             <div>
                                                                 {event.title}
                                                                 <div style={event.status===0 ?{background: "lightgray"}:{}} className={event.status===0 ?
                                                                     "":
                                                                     ("div_"+event.tipo.toLowerCase().replace(/ /g, "_")) }>
                                                                     <div style={{fontSize:"12px", height: "19px"}}>
                                                                         {
                                                                             event.status===0 ?
                                                                                 <StopIcon style={{backgroundColor: "gray", color: "gray"}}/>
                                                                                 :
                                                                                 <StopIcon className={event.tipo.toLowerCase().replace(/ /g, "_")}/>
                                                                         }

                                                                         <span className="" style={{verticalAlign: "top"}}>
                                                        {
                                                            event.start.getHours()+':'+
                                                            (event.start.getMinutes()<10 ?
                                                                '0'+event.start.getMinutes().toString():
                                                                event.start.getMinutes()) +
                                                            ' ' +
                                                            event.start.toLocaleTimeString('en-US').split(' ')[1]
                                                        }
                                                        </span>
                                                                     </div>
                                                                 </div>
                                                             </div>
                                                         )
                                                     }
                                                 }}
                            />
                            <br/>
                            {/* * * T O D O S  L O S  R O L E S * * */}
                            <div className="row" style={{margin: "20px 0 50px 0"}}>
                                {
                                    eventosPorRol.map((event, i)=>
                                        <div className="col l4 s12" style={{marginBottom: "5px"}} key={i}>
                                            <div className={"div_"+event.nombre.toLowerCase().replace(/ /g, "_")}>
                                                <div style={{fontSize:"12px", height: "22px"}}>
                                                    <StopIcon className={event.nombre.toLowerCase().replace(/ /g, "_")}/>
                                                    <span style={{verticalAlign: "super"}}> { event.nombre}</span>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                    </Paper>
                </Grid>

                {/* * * * M O D A L  D E L  E V E N T O * * * */}
                <Dialog open ={openModal} onClose={handleCloseModal} aria-labelledby="form-dialog-title" >
                    <DialogTitle className="titleNtfcAddEvent" id="form-dialog-title" onClose={handleCloseModal}>
                        {
                            datosEvento.status===0 ?
                                <span style={{color: "red"}}>Evento Cancelado</span> :
                                datosEvento && datosEvento.id.length > 0 ?
                                    <span>Editar evento</span> :
                                    <span>Agregar evento</span>
                        }
                    </DialogTitle>
                    <DialogContent className="nopadding" >
                        <div className="row" style={{display: "none"}}>
                            <Button onClick={()=>enviarInvitacion(datosEvento)}
                                    className="MuiBadge-colorSecondary">
                                Enviar invitación
                            </Button>
                        </div>
                        <div className="row DateHourNewEvent">
                            <div className="col l6 s12">
                                <div className="row" style={{display: "block", padding: "0px"}}>
                                    <div className="col l2 s2 labelDateNewEvent">
                                        <img src="/assets/img/icons/calendar_23.png" className="iconSideMenu icon_popup" alt="calNewEvent"/>
                                    </div>
                                    <div className="col l10 s10" style={{display: "table"}}>
                                        {
                                            datosEvento.id.length > 0 && noEditarFecha ?
                                            <span style={{display:"table-cell", verticalAlign: "middle"}}>
                                                {
                                                    datosEvento.status === 2 ?
                                                    new Date(datosEvento.fechaReagenda).toLocaleDateString('es-MX',
                                                        { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })
                                                        :
                                                    new Date(datosEvento.fecha).toLocaleDateString('es-MX',
                                                        { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })
                                                }
                                            </span>
                                            :
                                            <DatePicker
                                                className="inputDateNewEvent"
                                                name="eventDate"
                                                locale="es"
                                                minDate={new Date()}
                                                placeholderText="Fecha de evento"
                                                dateFormat="EEEE d 'de' MMMM 'de' y"
                                                selected={eventDate}
                                                onChange={handleChangeEventDate}
                                                modalTransparent={false}
                                                animationType={"fade"}
                                            />
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="col l6 s12">
                                <div className="row" style={{display: "block"}}>
                                    <div className="labelDateNewEvent col l2 s2 desktopshow">
                                        <span className="">de</span>
                                    </div> 
                                    <div className="col l4 s5 ">
                                        {
                                            datosEvento.id.length > 0 && noEditarFecha ?
                                                <span style={{display:"table-cell", verticalAlign: "middle"}}>
                                                {datosEvento.start}
                                            </span>
                                                :
                                            <input className="inputHourNewEvent" type="time" name="start" id="start"
                                                value={datosEvento.start} onChange={handleChangeDatosEvento}/>
                                        }
                                    </div>
                                    <div className="labelDateNewEvent col l2 s2 ">
                                        <span className="">a</span>
                                    </div> 
                                    <div className="col l4 s5 ">
                                        {
                                            datosEvento.id.length > 0 && noEditarFecha ?
                                                <span style={{display:"table-cell", verticalAlign: "middle"}}>
                                                {datosEvento.end}
                                            </span>
                                                :
                                            <input className="inputHourNewEvent" type="time" name="end" id="end"
                                                value={datosEvento.end} onChange={handleChangeDatosEvento}/>
                                        }
                                    </div>
                                </div>
                            </div>
                            {
                                datosEvento && datosEvento.id.length > 0 && datosEvento.correoPresentador === user.correo
                                && new Date(datosEvento.status === 2 ? datosEvento.fechaReagenda: datosEvento.fecha).getTime()>new Date().getTime() &&
                                <div className="col l2" style={{display: "block"}}>
                                    <Button className="MuiBadge-colorSecondary" style={{float:"right"}}
                                            onClick={()=>reagendar(datosEvento)}>
                                        Reagendar</Button>

                                </div>
                            }
                        </div>
                        {
                            datosEvento.correoPresentador === user.correo &&
                            (new Date(datosEvento.status === 2 ? datosEvento.fechaReagenda: datosEvento.fecha).getTime()>new Date().getTime() || datosEvento.id.length === 0) ?
                                <div className="row">
                                    <div className="col l6">
                                        Tipo de evento<br/>
                                        <NativeSelect style={{width:"100%"}} id="idTipoEvento" name="idTipoEvento"
                                                      value={idTipoEvento} onChange={handleChangeTipoEvento}>
                                            <option value="0">Seleccionar evento</option>
                                            {
                                                eventosPorRol.map((el, index) =>
                                                    <option value={el.idTipo} key={index}
                                                            style={{color: "red"}}>{el.nombre} </option>
                                                )
                                            }
                                        </NativeSelect>
                                    </div>
                                    <div className="col l6">
                                        {
                                            editaTitulo &&
                                            <div className="col l12">
                                                Título de tu evento:
                                                <input type="text" name="titulo" value={datosEvento.titulo}
                                                       onChange={handleChangeDatosEvento} style={{border: `1px solid #00AC46`}}/>
                                            </div>
                                        }
                                        { modalidad2 &&
                                        <div className="col l12">
                                        <FormGroup row>
                                            <Typography component="div">
                                                <Grid component="label" container alignItems="center" spacing={1}>
                                                    <Grid item style={{color: '#00AC46', fontSize: "16px"}}>Individual</Grid>
                                                    <Grid item>
                                                        <AntSwitch checked={stateSwitch.checkedModalidad1} onChange={handleChangeSwitch} name="checkedModalidad1" />
                                                    </Grid>
                                                    <Grid item style={{color: '#00AC46', fontSize: "16px"}}>Con Invitados</Grid>
                                                </Grid>
                                            </Typography>
                                        </FormGroup>
                                        </div>
                                        }
                                        { modalidad2 &&
                                        <div className="col l12">
                                        <FormGroup row>
                                            <Typography component="div">
                                                <Grid component="label" container alignItems="center" spacing={1}>
                                                    <Grid item style={{color: '#00AC46', fontSize: "14px"}}>Presencial</Grid>
                                                    <Grid item>
                                                        <AntSwitch checked={stateSwitch.checkedModalidad2}
                                                                   onChange={handleChangeSwitch}
                                                                   name="checkedModalidad2"/>
                                                    </Grid>
                                                    <Grid item style={{
                                                        color: '#00AC46',
                                                        fontSize: "14px"
                                                    }}>Virtual</Grid>
                                                </Grid>
                                            </Typography>
                                        </FormGroup>
                                        </div>
                                        }
                                    </div>
                                </div>
                                :
                                <div className="row">
                                    <div className="col l6">
                                        <b>Evento:</b><br/>
                                        {   editaTitulo ?
                                            <p>{datosEvento.titulo.length>0? datosEvento.titulo: datosEvento.tipo}</p>
                                            :
                                            <p>{datosEvento.tipo}</p>
                                        }
                                    </div>
                                    <div className="col l6" style={{textAlign: "center"}}>
                                        {
                                            editaTitulo &&
                                            <div className="col l12">
                                                <p>{datosEvento.titulo.length>0? datosEvento.titulo: datosEvento.tipo}</p>
                                            </div>
                                        }
                                        {  (modalidad1 || modalidad2) &&
                                        <>
                                            <b>Modalidad:</b><br/>
                                            { modalidad1 &&
                                            <p className="col l4" style={{textAlign: "center"}}>{datosEvento.modalidad1}</p>
                                            }
                                            {modalidad1 &&
                                            <p className="col l4" style={{textAlign: "center"}}>/</p>
                                            }
                                            { modalidad2 &&
                                            <p className={modalidad1?'col l4':""} style={modalidad1?{}:{textAlign: "center"}}>
                                                {datosEvento.modalidad2}</p>
                                            }
                                        </>
                                        }
                                    </div>
                                </div>
                        }
                        {
                            datosEvento.correoPresentador === user.correo &&
                            (new Date(datosEvento.status === 2 ? datosEvento.fechaReagenda: datosEvento.fecha).getTime()>new Date().getTime() || datosEvento.id.length === 0)  &&
                            <div className="row">
                                <img src="/assets/img/icons/contacts_23.png" className="iconSideMenu icon_popup" alt="calNewEvent"/>
                                <span style={{fontSize: "22px", color: "gray"}}>Invitar</span>
                            </div>
                        }
                        {
                            (datosEvento.correoPresentador !== user.correo) &&
                            <div className="row">
                                <b>
                                    {
                                        (userEstructuraAll.filter(u=>u.idu===datosEvento.iduPresentador).length>0 ?
                                            userEstructuraAll.filter(u=>u.idu===datosEvento.iduPresentador)[0].nombre +
                                            ' ' + userEstructuraAll.filter(u=>u.idu===datosEvento.iduPresentador)[0].apellido:
                                        datosEvento.correoPresentador) + ' '
                                    }
                                </b>
                                te esta invitando a un evento.
                            </div>
                        }
                        <div className="row">
                            <div className="col l12 s12">
                                {
                                    datosEvento.id.length === 0 && userEstructuraAll.length===0 ?
                                        <CircularProgress color="inherit" size={20} />
                                        :
                                        <>{
                                            datosEvento.correoPresentador === user.correo &&
                                            (new Date(datosEvento.status === 2 ? datosEvento.fechaReagenda: datosEvento.fecha).getTime()>new Date().getTime() || datosEvento.id.length === 0) &&
                                        <div className="col l4 s12 fieldUsersInvite" >
                                            <p className="labelInvite">Perfiles</p>
                                            {
                                                datosEvento.correoPresentador === user.correo &&
                                                (new Date(datosEvento.status === 2 ? datosEvento.fechaReagenda: datosEvento.fecha).getTime()>new Date().getTime() || datosEvento.id.length === 0) &&
                                                <div className="contProfilesInvite">
                                                    <p style={{cursor: "pointer", fontWeight: "600"}}
                                                       onClick={() => filtrarExternos("", "Externos")}>- Externos</p>
                                                    {
                                                        segmento.map((sgmn, i) =>
                                                            <div key={i}>
                                                                    <span style={{cursor: "pointer"}}
                                                                          onClick={() => filtrarExternos(sgmn, sgmn)}>
                                                                        - {sgmn}
                                                                    </span><br/>
                                                            </div>
                                                        )
                                                    }
                                                    <p style={{cursor: "pointer", fontWeight: "600"}}
                                                       onClick={() => filtrarInternos(-1, "Internos")}>
                                                        - Internos ({userEstructuraAll.length})
                                                    </p>
                                                    {
                                                        userEstructuraAll.length>0 &&
                                                        <div>
                                                            <span style={{cursor: "pointer"}}
                                                                  onClick={() => filtrarInternos(1, "Presentadores")}>
                                                            - Presentadores (
                                                                {userEstructuraAll.filter((el,i)=>el.rol[0].value===1).length}
                                                                )
                                                            </span><br/>
                                                            <span style={{cursor: "pointer"}}
                                                                  onClick={() => filtrarInternos(3, "Líderes")}>
                                                                - Líderes (
                                                                {userEstructuraAll.filter((el,i)=>el.rol[0].value===3).length}
                                                                )
                                                            </span><br/>
                                                            <span style={{cursor: "pointer"}}
                                                                  onClick={() => filtrarInternos(5, "Gerentes")}>
                                                                - Gerentes (
                                                                {userEstructuraAll.filter((el,i)=>el.rol[0].value===5).length}
                                                                )
                                                            </span><br/>
                                                            <span style={{cursor: "pointer"}}
                                                                  onClick={() => filtrarInternos(8, "Area M")}>
                                                                - Area M (
                                                                {userEstructuraAll.filter((el,i)=>el.rol[0].value===8).length}
                                                                )
                                                            </span><br/>
                                                            <span style={{cursor: "pointer"}}
                                                                  onClick={() => filtrarInternos(9, "Sales Director")}>
                                                                - Sales Director (
                                                                {userEstructuraAll.filter((el,i)=>el.rol[0].value===9).length}
                                                                )
                                                            </span><br/>
                                                            {
                                                                user.rol[0].value===0 &&
                                                                <>
                                                                <span style={{cursor: "pointer"}}
                                                                      onClick={() => filtrarInternos(0, "Admin")}>
                                                                    - Admin (
                                                                    {userEstructuraAll.filter((el, i) => el.rol[0].value === 0).length}
                                                                    )
                                                                </span> <br/>
                                                                </>
                                                            }
                                                            {/*
                                                                <span style={{cursor: "pointer"}}
                                                                      onClick={() => filtrarInternos(55)}>
                                                                    - Staff (
                                                                        {userEstructuraAll.filter((el,i)=>el.rol[0].value===55).length}
                                                                    )
                                                                </span>
                                                                <br/>
                                                                */}
                                                        </div>
                                                    }
                                                </div>
                                            }
                                        </div>
                                        }</>
                                }
                            
                                {
                                    personas.length>0 &&
                                    <div className="col l4 s12 fieldPeopleInvite">
                                        <div className="" >
                                            {/*<NativeSelect disabled style={{width: "100%"}}>
                                            <option>Personas</option>
                                        </NativeSelect>*/}
                                        <p className="labelInvite">Personas </p>
                                            {/*Agregar checkbox para seleccionar todos los usuarios*/}
                                            <div style={{marginTop: "10px"}}>
                                                <FormGroup row>
                                                    <FormControlLabel
                                                        control={<GreenCheckbox checked={checkSelectAll}
                                                        onChange={handleChangeSelectAll} name="seRepite"/>}
                                                        label={"Invitar a todos en " + titlePersonas } className="inviteAllProfileCheck" 
                                                    />
                                                </FormGroup>
                                            </div>
                                            <br/>
                                            <Autocomplete
                                                multiple
                                                id="checkboxes-tags-demo"
                                                options={personas}
                                                disableCloseOnSelect
                                                disableClearable
                                                open={true}
                                                freeSolo
                                                PopperComponent={Paper}
                                                noOptionsText={'Sin Resultados'}
                                                getOptionLabel={(option) => (option.nombre + ' ' +
                                                    (option.apellido && option.apellido !== null && option.apellido.length>0 ?
                                                        option.apellido: ''))}
                                                getOptionSelected={(option, value) =>
                                                    esUsuarioInterno ?
                                                        (option.rol[0].value===0? option.correo === value.correo: option.idu === value.idu) :
                                                        option.id === value.id
                                                }
                                                /*
                                                renderOption={(option, { selected }) => (
                                                    <React.Fragment>
                                                        <Checkbox
                                                            icon={icon}
                                                            checkedIcon={checkedIcon}
                                                            style={{ marginRight: 8 }}
                                                            checked={selected}
                                                        />
                                                        {option.nombre}
                                                    </React.Fragment>
                                                )}
                                                */
                                                style={{ width: "100%", tagSizeSmall: "0px" }}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        variant="outlined"
                                                        label={titlePersonas}
                                                        placeholder="Buscar..."
                                                        style={{ fontSize: "0px !important", tagSizeSmall: "0px" }}
                                                    />
                                                )}
                                                onChange={(event, value) => handleChangeAutoCmpl(event, value)}
                                            />
                                            {
                                                /*
                                                personas.map((prsn, i)=>
                                                    <div key={i}>
                                                        {
                                                            esUsuarioInterno &&
                                                            <span key={i} style={{cursor: "pointer"}}
                                                                    onClick={()=>agregarInvitado(prsn)}>
                                                                {prsn.idu + " " + prsn.nombre + " " + prsn.apellido}
                                                            </span>
                                                                ||
                                                            <span key={i} style={{cursor: "pointer"}}
                                                                  onClick={()=>agregarInvitado(prsn)}>
                                                                {prsn.id + " " + prsn.nombre + " " + prsn.apellido}
                                                            </span>
                                                        }
                                                        <br/>
                                                    </div>
                                                )
                                                */
                                            }
                                        </div>
                                    </div>
                                }
                                {
                                (listaInvitados.length>0) &&
                                    <div className="col l4 s12 fieldConfirmInvite fieldUsersSelectedInvite">
                                        <p className="labelInvite">Invitados</p>
                                        {
                                            listaInvitados.map((el, index)=>
                                            <div key={index} className="row">
                                                {
                                                    (datosEvento.correoPresentador === user.correo) ?
                                                    <>
                                                        <div className="col l9 listInvitedU"
                                                                style={{padding: '0px', margin: '0px', fontSize: "12px", marginTop: "5px"}}>
                                                            <p>{ el.nombre + (el.apellido && el.apellido !==null && el.apellido.length>0 ? ' ' + el.apellido: '') }</p>
                                                        </div>
                                                        {
                                                        (new Date(datosEvento.status === 2 ? datosEvento.fechaReagenda: datosEvento.fecha).getTime()>new Date().getTime() || datosEvento.id.length === 0) &&
                                                        <div className="col l3" onClick={()=>quitarInvitado(el)}
                                                             style={{padding: '5px', margin: '0px',
                                                                 cursor: "pointer", textAlign: "center"}}>
                                                            <CancelIcon style={{color:'grey', verticalAlign: 'middle'}}/>
                                                        </div>
                                                        }
                                                    </>
                                                    :
                                                    <>
                                                        {index === 0 &&
                                                        <div className="col l9 listInvitedU"
                                                                style={{padding: '0px', margin: '0px', fontSize: "12px", marginTop: "5px"}}>
                                                            <p>{ user.nombre +
                                                            (user.apellido && user.apellido !==null && user.apellido.length>0 ?
                                                                ' ' + user.apellido: '') }</p>
                                                        </div>
                                                        }
                                                    </>
                                                }
                                            </div>
                                            )
                                        }
                                    </div>
                                }
                            </div>
                        </div>

                        <div className="row">
                            {
                                datosEvento.correoPresentador===user.correo &&
                                (new Date(datosEvento.status === 2 ? datosEvento.fechaReagenda: datosEvento.fecha).getTime()>new Date().getTime() || datosEvento.id.length === 0) ?
                                    <>
                                        <div className="optionsAddCont row">
                                            {/* <LocationOnOutlinedIcon className="col l1" color="disabled" fontSize="large"/> */}
                                            <div className="col l1 s2">
                                                <img src="/assets/img/icons/pointer_23.png" className="iconSideMenu icon_popup" alt="calNewEvent"/>    
                                            </div>
                                            <div className="col l11 s10">
                                                <input className="col l10 optionsAdd" type="text" name="direccion" 
                                                placeholder="Dirección / Link" value={datosEvento.direccion} 
                                                onChange={handleChangeDatosEvento} style={{border: `1px solid #00AC46`}}/>
                                            </div>
                                            
                                            
                                        </div>
                                        <div className="optionsAddCont row">
                                            {/* <MenuBookOutlinedIcon className="col l1" color="disabled" fontSize="large"/> */}
                                            <div className="col l1 s2">
                                                <img src="/assets/img/icons/book_23.png" className="iconSideMenu icon_popup" alt="calNewEvent"/>
                                            </div>
                                            <div className="col l11 s10">
                                                <textarea className="col l10 optionsAdd" rows="4" cols="50" name="notas"
                                                placeholder="Notas..." value={datosEvento.notas}
                                                onChange={handleChangeDatosEvento} style={{border: `1px solid #00AC46`}}/>
                                            </div>
                                        </div>
                                    </> :
                                    <>
                                        <div className="optionsAddCont row">
                                            {/* <LocationOnOutlinedIcon className="col l1" color="disabled" fontSize="large"/> */}
                                            <div className="col l1 s2">
                                                <img src="/assets/img/icons/pointer_23.png" className="iconSideMenu icon_popup" alt="calNewEvent"/>
                                            </div>
                                            <div className="col l11 s10" style={{textAlign: "left"}}>
                                                <span>
                                                    {datosEvento.direccion}
                                                </span>
                                            </div>
                                        </div>
                                        <div className="optionsAddCont row">
                                            {/* <MenuBookOutlinedIcon className="col l1" color="disabled" fontSize="large"/> */}
                                            <div className="col l1 s2">
                                                <img src="/assets/img/icons/book_23.png" className="iconSideMenu icon_popup" alt="calNewEvent"/>
                                            </div>
                                            <div className="col l11 s10" style={{textAlign: "left"}}>
                                                <span>{datosEvento.notas}</span>
                                            </div>
                                        </div>
                                    </>
                            }
                            {
                                
                            datosEvento.correoPresentador === user.correo && datosEvento.id.length === 0 && EventoRepetable !== false ?
                                <>
                                    <div className="col l12">
                                        <div className="col l5" style={{marginTop: "10px", textAlign: "end"}}>
                                            <FormGroup row>
                                                <FormControlLabel
                                                    control={<GreenCheckbox checked={seRepite}
                                                                            onChange={handleChangeSeRepite}
                                                                            name="seRepite"/>}
                                                    label="Este evento se repite" style={{color: "#00AC46"}}
                                                />
                                            </FormGroup>
                                        </div>
                                        {
                                            seRepite &&
                                            <div className="col l7">
                                                <NativeSelect id="frecuencia" name="frecuencia"
                                                        style={{marginTop: "10px"}}
                                                        value={datosEvento.frecuencia}
                                                        onChange={handleChangeDatosEvento}>
                                                    <option value="0">Elige la frecuencia</option>
                                                    <option value="1">Semanalmente</option>
                                                    <option value="2">Mensualmente</option>
                                                    <option value="3">Anualmente</option>
                                                </NativeSelect>
                                            </div>
                                        }
                                    </div>
                                    {
                                        seRepite &&
                                        <div className="col l12">
                                            <p className="col l5">Se repite hasta:</p>
                                            <DatePicker className="col l5"
                                                name="eventDateHasta"
                                                locale="es"
                                                minDate={new Date()}
                                                placeholderText="Hasta"
                                                dateFormat="EEEE d 'de' MMMM 'de' y"
                                                selected={eventDateHasta}
                                                onChange={handleChangeEventDateHasta}
                                                modalTransparent={false}
                                                animationType={"fade"}
                                                disabled={datosEvento.id.length > 0 && noEditarFecha}
                                            />
                                        </div>
                                    }
                                </>
                            :
                            datosEvento.correoPresentador === user.correo &&
                                <div className="col l12">
                                    <div className="col l5" style={{marginTop: "10px", textAlign: "end"}}>
                                    </div>
                                </div>
                                
                            }
                        </div>
                    </DialogContent>
                    {
                        datosEvento.correoPresentador===user.correo &&
                        <DialogActions>
                            {
                                datosEvento && datosEvento.id.length>0 && validateMonday() &&
                                <Button onClick={()=>eliminarEvento(datosEvento)}
                                        style={{backgroundColor: "#B42C2E", color:"#FFF"}}>
                                    Cancelar Evento
                                </Button>
                                /*:
                                <Button onClick={handleCloseModal} color="primary" className="MuiBadge-colorPrimary">
                                    Cancelar
                                </Button>
                                */
                            }
                            {
                                datosEvento && datosEvento.id.length > 0 &&
                                new Date(datosEvento.status === 2 ? datosEvento.fechaReagenda: datosEvento.fecha).getTime() > new Date().getTime() &&
                                <Button className="MuiBadge-colorSecondary" onClick={editarEvento} color="inherit">
                                    Actualizar
                                </Button>
                            }
                            {   datosEvento.id.length===0 &&
                            <Button className="MuiBadge-colorSecondary" onClick={newEvent} disabled={SaveBtnState} color="inherit">
                                Guardar {datosEvento.id}
                            </Button>
                            }

                        </DialogActions>
                    }
                </Dialog>
                <Dialog
                    open={openQuestion}
                    onClose={handleCloseQuestion}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">Cancelar eventos</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {question}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        {
                            datosEvento.seRepite ?
                                <>
                                <Button onClick={handleCancelEventsAll} color="primary">
                                    Todos los eventos
                                </Button>
                                <Button onClick={handleCancelEvent} color="primary" autoFocus>
                                    Este evento
                                </Button>
                                </>
                                :
                                <>
                                <Button  variant="contained" className="MuiBadge-colorSecondary" style={{backgroundColor: "#00AC46"}} onClick={()=>setOpenQuestion(false)} color="primary">
                                    Cancelar
                                </Button>
                                <Button  variant="contained" className="MuiBadge-colorSecondary" style={{backgroundColor: "#00AC46"}} onClick={handleCancelEvent} color="primary" autoFocus>
                                    Aceptar
                                </Button>
                                </>
                        }

                    </DialogActions>
                </Dialog>

                <Dialog
                    open={openQuestEdit}
                    onClose={()=>setOpenQuestEdit(false)}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        {
                            mostrarAsistencia ?
                                'Enviar correo a invitados que asistieron al evento' :
                                'Opciones de Evento'
                        }
                    </DialogTitle>
                    <DialogContent>
                        {   mostrarAsistencia ?
                            <div>
                                {
                                    datosEvento.status===3 &&
                                    <div align="center"><h5 style={{color: "red"}}>El evento no se realizó</h5></div>
                                }
                                <div align="center"><p style={{color: "red"}}>{validateDateMonday()}</p></div>
                                Mostrar la lista de invitados para el proceso de envio de mail para los que asistieron
                                
                                {
                                    validateMonday() && datosEvento.status!==3 && datosEvento.listaInvitados.filter(invt => invt.sendMail).length === 0 ?
                                    <>
                                        <Asistencia event={datosEvento} yaEnvioCorreo={false} validateMonday={validateMonday()}/>
                                    </>
                                        :
                                    <>
                                        <br/>
                                        {/*<b>Ya paso la fecha para realizar el proceso de enviar correo a los usuarios invitados.</b>*/}
                                        <Asistencia event={datosEvento} yaEnvioCorreo={true} validateMonday={validateMonday()}/>
                                    </>
                                }
                            </div>:
                            <div>
                                {()=>validateDateMonday()}
                            </div>
                        }
                    </DialogContent>
                    <DialogActions>
                        {
                            mostrarAsistencia ?
                                <>
                                {
                                    validateMonday() && datosEvento.status!==3 && datosEvento.listaInvitados.filter(invt => invt.sendMail).length === 0 &&
                                    <>
                                        <Button onClick={noSeRealizo} style={{backgroundColor: "#B42C2E", color:"#FFF"}}>
                                            No se Realizó
                                        </Button>
                                    </>
                                }
                                    <Button  variant="contained" className="MuiBadge-colorSecondary" style={{backgroundColor: "#00AC46"}} onClick={()=>{
                                        setOpenQuestEdit(false)
                                        setMostrarAsistencia(false)
                                    }} color="primary" autoFocus>
                                        Cancelar
                                    </Button>
                                {
                                    (datosEvento.idTipo===1 || datosEvento.idTipo===2 || datosEvento.idTipo===4) &&
                                    validateMonday() && datosEvento.status!==3 && datosEvento.listaInvitados.filter(invt => invt.sendMail).length === 0 &&
                                    <>
                                        <Button  variant="contained" className="MuiBadge-colorSecondary" style={{backgroundColor: "#00AC46"}} onClick={enviarCorreo} color="primary" autoFocus>
                                            Enviar
                                        </Button>
                                    </>
                                }
                                </>
                                :
                                <>
                                    <Button  variant="contained" className="MuiBadge-colorSecondary" style={{backgroundColor: "#00AC46"}} onClick={()=>{
                                        setOpenQuestEdit(false);
                                        setOpenModal(true);
                                        setMostrarAsistencia(false);
                                    }} color="primary">
                                        Ver Evento
                                    </Button>
                                    {
                                        /*
                                        (datosEvento.idTipo===1 || datosEvento.idTipo===2 || datosEvento.idTipo===4) &&
                                        datosEvento.iduPresentador === user.idu && validateMonday() &&
                                        <Button onClick={() => setMostrarAsistencia(true)} color="primary" autoFocus>
                                            Lista de Asistencia
                                        </Button>
                                        */
                                    }
                                    {
                                        (datosEvento.idTipo===1 || datosEvento.idTipo===2 || datosEvento.idTipo===4) &&
                                        datosEvento.iduPresentador === user.idu &&
                                        <Button   variant="contained" className="MuiBadge-colorSecondary" style={{backgroundColor: "#00AC46"}} onClick={() => setMostrarAsistencia(true)} color="primary" autoFocus>
                                            Lista de Asistencia
                                        </Button>
                                    }
                                </>
                        }

                    </DialogActions>
                </Dialog>
                {
                    // Aqui vamos a cuestionar si esta seguro de marcar que no se realizo
                }
                <Dialog
                    open={openQuestNoSeRlzo}
                    onClose={()=>setOpenQuestNoSeRlzo(false)}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        Está seguro de marcar el evento como "No se realizo"?
                    </DialogTitle>
                    <DialogContent>

                    </DialogContent>
                    <DialogActions>

                        <Button  variant="contained" className="MuiBadge-colorSecondary" style={{backgroundColor: "#00AC46"}} onClick={()=>{
                            setOpenQuestNoSeRlzo(false)
                        }} color="primary" autoFocus>
                            Cancelar
                        </Button>
                        <Button  variant="contained" className="MuiBadge-colorSecondary" style={{backgroundColor: "#00AC46"}} onClick={aceptoNoSeRealizo} color="primary" autoFocus>
                            Aceptar
                        </Button>

                    </DialogActions>
                </Dialog>
            </Grid>
            }
        </div>
    );
}